import React, { useState, memo, useMemo, useRef } from 'react';
import qs from 'qs';
import moment from 'moment';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import MaterialTable from 'material-table';
import { useLocation } from 'react-router-dom';

import { useAuthAPI } from 'hooks/use-auth-api';
import { PaymentService } from 'services/api/payment';
import { errorService , warnService } from 'services/alert/services';
import { swap } from 'utils/object-swap';
import { mapFilters } from 'utils/map-table-filters';
import endpoints from 'api/endpoints';
import {
  PaymentType,
  PaymentTransactionStatus,
  currencies,
  paymentTypeTranslations,
} from 'constants/payment';
import { URLHelper } from 'shared/helpers/URLHelper';

const isWithdraw = (type) => type === PaymentType.withdraw;
const isWaitingForApproval = (status) =>
  status?.includes?.(PaymentTransactionStatus.waitingForApproval);

const paymentTransactionStatusTranslations = {
  [PaymentTransactionStatus.placeholder]: 'Без статуса',
  [PaymentTransactionStatus.created]: 'Создано',
  [PaymentTransactionStatus.processed]: 'Обработано',
  [PaymentTransactionStatus.refunded]: 'Возмещено',
  [PaymentTransactionStatus.failedToCreate]: 'Не удалось создать',
  [PaymentTransactionStatus.paymentRejected]: 'Отклонена',
  [PaymentTransactionStatus.waitingForApproval]: 'Ожидание подтверждения',
  [PaymentTransactionStatus.approved]: 'Подтверждено',
  [PaymentTransactionStatus.rejectedByAdmin]: 'Отказано',
  [PaymentTransactionStatus.canceled]: 'Отменено',
};

const paymentStatusFilterLookup = { ...paymentTransactionStatusTranslations };
delete paymentStatusFilterLookup[PaymentTransactionStatus.waitingForApproval];

const transformType = (type) => {
  return swap(paymentTypeTranslations)[type] || '';
};

const transformFilterQueries = {
  type: transformType,
};

const mapStatus = (status) => {
  return paymentTransactionStatusTranslations[status] || 'Неизвестный статус';
};

const mapCurrency = (currency) => {
  return currencies[currency] || currency;
};

const getRowStyle = (rowData) => {
  if (!rowData?.user?.verificationInfo?.verified) {
    return {
      backgroundColor: '#4fdbc9',
    };
  }
  return;
};

const PaymentsTable = ({
  type,
  status,
  title,
  pageType,
  onRejectPayout,
  openRecreatePayoutDialog,
  onUserClick,
  tableRef,
  unequalStatus,
}) => {
  const request = useAuthAPI();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  // TODO: remove material-table and find a replacement for it and remove this useRef and refactor it
  const isFirstRender = useRef(true);

  const searchQueryFilters = qs.parse(search.substring(1));

  const handleData = async (query) => {
    try {
      const filters = mapFilters(query.filters, transformFilterQueries);

      const orderQuery = query.orderBy
        ? { orderBy: query.orderBy.field, orderDirection: query.orderDirection }
        : {};
      const filterByStatus = status ? { status: status } : {};

      const page = isFirstRender.current
        ? !isNaN(+searchQueryFilters.page)
          ? +searchQueryFilters.page
          : query.page
        : Number(query.page);

      isFirstRender.current = false;

      URLHelper.replaceSearchParams(qs.stringify({ ...filters, page, pageSize: query.pageSize }));

      const response = await request(endpoints.payments, {
        limit: query.pageSize,
        offset: page * query.pageSize,
        unequalStatus,
        ...filterByStatus,
        ...orderQuery,
        ...filters,
        type,
      });
      return {
        data: response.data.data,
        page,
        totalCount: response.data.total,
        pageSize: query.pageSize,
      };
    } catch (e) {
      errorService.sendError('Some error occurred', e);
    }
  };

  const handleApprovePayout = async (paymentNo, user) => {
    if (isLoading) return;

    if (!user?.verificationInfo?.verified) {
      warnService.sendWarn('Пользователь не верифицирован');
      return;
    }

    setIsLoading(true);
    try {
      await PaymentService.approvePayment(paymentNo);
      tableRef.current.retry();
    } catch (e) {
      errorService.sendError(e.response?.data?.message ?? e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectPayout = async (paymentNo) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await onRejectPayout(paymentNo);
    } catch (e) {
      errorService.sendError(e?.response?.message ?? e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Пользователь',
        field: 'user',
        sorting: false,
        defaultFilter: searchQueryFilters.user,
        render: ({ user }) => (
          <div style={{ cursor: 'pointer', color: '#9c27b0' }} onClick={() => onUserClick(user)}>
            {user.email}
          </div>
        ),
      },
      {
        title: 'Дата',
        field: 'created',
        type: 'date',
        defaultFilter: searchQueryFilters.created,
        render: ({ created }) => <div>{moment(created).format('HH:mm:ss DD-MM-YYYY')}</div>,
      },
      {
        title: 'Сумма',
        field: 'amount',
        defaultFilter: searchQueryFilters.amount,
      },
      {
        title: 'Статус',
        field: 'status',
        sorting: false,
        filtering: pageType !== PaymentType.withdrawRequest,
        lookup: paymentStatusFilterLookup,
        defaultFilter: searchQueryFilters.status,
        render: ({ status }) => <div>{mapStatus(status)}</div>,
      },
      {
        title: 'Номер платежа',
        field: 'paymentNo',
        sorting: false,
        filtering: false,
      },
      {
        title: 'Provider type',
        field: 'providerType',
        sorting: false,
        filtering: false,
      },
    ].concat(
      isWaitingForApproval(status)
        ? [
            {
              title: 'Конвертированная сумма',
              filtering: false,
              sorting: false,
              render: ({ convertedToAmount }) => <div>{convertedToAmount}</div>,
            },
            {
              title: 'Валюта',
              filtering: false,
              sorting: false,
              render: ({ convertedToCurrency }) => <div>{mapCurrency(convertedToCurrency)}</div>,
            },
            {
              title: 'Действия',
              filtering: false,
              sorting: false,
              render: ({ type, status, _id, user }) => (
                <div>
                  {type === PaymentType.withdraw &&
                    status === PaymentTransactionStatus.waitingForApproval && (
                      <>
                        <DoneIcon
                          onClick={() => handleApprovePayout(_id, user)}
                          style={{ cursor: 'pointer', marginRight: '45px' }}
                        />
                        <CancelIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleRejectPayout(_id);
                          }}
                        />
                      </>
                    )}
                </div>
              ),
            },
          ]
        : [],
      isWithdraw(pageType)
        ? [
            {
              title: 'Причина',
              filtering: false,
              sorting: false,
              render: ({ comment }) => <div>{comment?.message ?? ''}</div>,
            },
          ]
        : [],
    );
  }, []);

  return (
    <MaterialTable
      title={title}
      tableRef={tableRef}
      isLoading={isLoading}
      data={handleData}
      localization={{
        body: {
          editTooltip: 'Редактировать',
          deleteTooltip: 'Удалить',
          editRow: {
            deleteText: 'Вы уверены что хотите удалить пользователя?',
          },
        },
        toolbar: { searchPlaceholder: 'Поиск' },
        pagination: { labelRowsSelect: 'cтрок' },
        header: {
          actions: 'Действия',
        },
      }}
      columns={columns}
      actions={
        isWithdraw(pageType)
          ? [
              {
                icon: 'autorenew',
                tooltip: 'Пересоздать выплату',
                onClick: (_, rowData) => openRecreatePayoutDialog(rowData),
              },
            ]
          : []
      }
      options={{
        filtering: true,
        debounceInterval: 700,
        search: false,
        pageSize: 20,
        pageSizeOptions: [20, 40, 50],
        rowStyle: getRowStyle,
      }}
    />
  );
};

export default memo(PaymentsTable);
