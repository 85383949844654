import React, { useState, useEffect } from 'react';

const LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

export const HNotation = ({ orientation, borderWidth }) => {
  const [notation, setNotation] = useState([]);

  useEffect(() => {
    setNotation(orientation[0] === 'w' ? LETTERS : LETTERS.slice().reverse());
  }, [orientation]);

  return (
    <div
      style={{
        fontSize: 10,
        position: 'absolute',
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        bottom: -borderWidth + 1,
        left: borderWidth,
        right: 12,
        height: borderWidth,
      }}
    >
      {notation.map((n) => (
        <div key={n}>{n}</div>
      ))}
    </div>
  );
};
