import React, { useState } from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BeatLoader } from 'react-spinners';

import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAuth } from 'hooks/use-auth';
import { errorService } from 'services/alert/services';

export const Login = () => {
  const auth = useAuth();
  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: '0.8',
        background:
          "url('https://images.chesscomfiles.com/uploads/v1/article/18014.d6a8a42b.668x375o.259c017afa01@2x.jpeg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Вход в админ панель</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .trim()
                  .matches(/^[^а-яА-Я]+$/, 'Используйте только английские буквы!')
                  .max(48, 'Пароль очень длинный (макс. 48 символа)!')
                  .required('Введите пароль'),
                email: Yup.string()
                  .max(64, 'Слишком длинный почтовый ящик!')
                  .required('Введите почтовый ящик')
                  .email('Почтовый ящик должен быть в формате name@domain.com!'),
              })}
              onSubmit={async ({ email, password }) => {
                setLoading(true);
                const result = await auth.signin(email, password);
                setLoading(false);

                if (result) {
                  history.push('/dashboard');
                }
              }}
              render={({ values, errors, handleSubmit, handleChange }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item xs={12} style={{ width: 300 }}>
                        {errors.email || errors.password}
                      </Grid>
                      <Grid item xs={12} style={{ width: 300 }}>
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          error={!!errors.email}
                          name="email"
                          value={values.email}
                          label="Электронная почта"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ width: 300 }}>
                        <TextField
                          fullWidth
                          label="Пароль"
                          onChange={handleChange}
                          error={!!errors.password}
                          name="password"
                          type="password"
                          value={values.password}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ paddingTop: 20, width: 200 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          {loading ? (
                            <BeatLoader size={10} color="white" loading={true} />
                          ) : (
                            'Войти'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};
