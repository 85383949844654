export const rejectionReasons = {
  noPhoto: 'noPhoto',
  noFace: 'noFace',
  noDocument: 'noDocument',
  fullnameMismatch: 'fullnameMismatch',
  multiAccount: 'multiAccount',
};

export const rejectionReasonsLabels = {
  [rejectionReasons.noPhoto]: 'Отсутствие фотографии',
  [rejectionReasons.noFace]: 'Отсутствие лица',
  [rejectionReasons.noDocument]: 'Отсутствие документа',
  [rejectionReasons.fullnameMismatch]: 'Несовпадение ФИО',
  [rejectionReasons.multiAccount]: 'Мультиаккаунт',
};
