import React from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AlertService from 'services/alert';
import { useAuth } from 'hooks/use-auth';
import { useHistory, useLocation } from 'react-router-dom';

export default function Wrapper({ children }) {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  if (!location.pathname.startsWith('/login') && (!auth.token || auth.token === '')) {
    history.push('/login');
  }

  return (
    <div>
      {children}
      <AlertService />
      <NotificationContainer />
    </div>
  );
}
