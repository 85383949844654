import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AddIcon from '@mui/icons-material/Add';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { IconButton } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useAuthAPI } from 'hooks/use-auth-api';
import endpoints from 'api/endpoints';
import { URLHelper } from 'shared/helpers/URLHelper';
import { useDebounce } from 'hooks/use-debounce';

import { BALANCE_TYPE_MESSAGES } from '../../types/enums/index';

import CreateTransactionDialog from './create-transaction-dialog';
import ImportJackStatsDialog from './import-jack-stats-dialog';
import { TransactionsStatsByDays } from './transactions-stats-by-days';




const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  balance: {
    marginRight: '10px',
    fontWeight: 'bold',
  },
};

const useStyles = makeStyles(styles);

const mapTxType = (type) => {
  type = parseInt(type);
  switch (type) {
    case 1:
      return 'Дебет';
    case 2:
      return 'Кредит';
    case 3:
      return 'Рейк';
    case 4:
      return 'Резерв баланса';
    case 5:
      return 'Возврат резерва';
    case 6:
      return 'Списание резерва';
    case 7:
      return 'Рейкбек за игру';
    case 8:
      return 'Платеж';
    case 9:
      return 'Выплата';
    case 10:
      return 'Отмена выплаты';
    case 11:
      return 'Резерв выплаты';
    case 12:
      return 'Возврат платежа';
    case 13:
      return 'Начисление рейкбека на баланс';
    case 14:
      return 'Реферальный бонус';
    case 15:
      return 'Доля доходов от реферальной программы';
    case 16:
      return 'Вывод доходов от реферальной программы';
    case 17:
      return 'Резерв рейка';
    case 18:
      return 'Возврат рейка';
    default:
      return type;
  }
};

const transformType = [
  {
    text: 'Дебет',
    value: '1',
  },
  {
    text: 'Кредит',
    value: '2',
  },
  {
    text: 'Рейк',
    value: '3',
  },
  {
    text: 'Резерв баланса',
    value: '4',
  },
  {
    text: 'Возврат резерва',
    value: '5',
  },
  {
    text: 'Списание резерва',
    value: '6',
  },
  {
    text: 'Рейкбек',
    value: '7',
  },
  {
    text: 'Платеж',
    value: '8',
  },
  {
    text: 'Выплата',
    value: '9',
  },
  {
    text: 'Отмена выплаты',
    value: '10',
  },
  {
    text: 'Резерв выплаты',
    value: '11',
  },
  {
    text: 'Возврат платежа',
    value: '12',
  },
  {
    text: 'Начисление рейкбека на баланс',
    value: '13',
  },
];

const balanceTypeLookup = [
  {
    text: 'Free',
    value: 'coins',
  },
  {
    text: 'Cash',
    value: 'play',
  },
  {
    text: 'Bonus',
    value: 'referralBonus',
  },
];

const externalProviderType = {
  '0': 'AlgNet',
  '1': 'JackPoker',
  '2': 'Slots',
  '3': 'LiveDealer',
  '4': 'Sports',
  dicechess: 'Dicechess',
};

export const convertIntoArray = (values) => {
  const convertedArray = Object.entries(values).map(([key, value]) => ({
    text: value.replace(/([A-Z])/g, ' $1').trim(),
    value: key,
  }));
  return convertedArray;
};

const validateFilter = (filter) => {
  if (Array.isArray(filter) && !filter[0] && !filter[1]) {
    return false;
  }
  return true;
};

const formatAmount = (type, value) => {
  type = parseInt(type);
  switch (type) {
    case 1:
    case 5:
      return <span style={styles.increaseBalance}>+{value}</span>;
    case 2:
    case 3:
    case 4:
      return <span style={styles.decreaseBalance}>-{value}</span>;
    case 6:
      return <span style={styles.takingReservedBalance}>-{value}</span>;
    case 7:
      return <span style={styles.takingReservedBalance}>+{value}</span>;
    case 8:
      return <span style={styles.increaseBalance}>+{value}</span>;
    case 9:
      return <span style={styles.takingReservedBalance}>-{value}</span>;
    case 10:
      return <span style={styles.increaseBalance}>+{value}</span>;
    case 11:
      return <span style={styles.decreaseBalance}>-{value}</span>;
    case 12:
      return <span style={styles.increaseBalance}>+{value}</span>;
    case 13:
      return <span style={styles.increaseBalance}>+{value}</span>;
    default:
      return <span>{value}</span>;
  }
};

export const getFilters = (columnFilters) => {
  return columnFilters.reduce((acc, item) => {
    if (validateFilter(item.value)) acc[item.id] = item.value;
    return acc;
  }, {});
};

const MIN_PAGE_SIZE = 30;

const Transactions = () => {
  const params = useParams();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openImportStatsDialog, setOpenImportStatsDialog] = useState(false);

  const { userId } = params;
  const location = useLocation();
  const user = location.state;

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const columns = useMemo(
    () => [
      {
        header: 'Пользователь',
        accessorKey: 'user',
        Cell: ({ cell }) => {
          return cell.getValue()?.email;
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Дата',
        accessorFn: (row) => new Date(row.created),
        accessorKey: 'created',
        filterVariant: Array.isArray(searchQueryFilters.created) ? 'range' : 'equals',
        sortingFn: 'datetime',
        enableColumnFilterModes: true,
        Cell: ({ cell }) => moment.utc(cell.getValue()).format('HH:mm:ss DD-MM-YYYY'),
        type: 'date',
        muiTableHeadCellFilterTextFieldProps: { InputProps: { inputProps: { type: 'date' } } },
      },
      {
        header: 'Тип',
        accessorKey: 'type',
        filterVariant: 'multi-select',
        filterSelectOptions: transformType,
        Cell: ({ cell }) => <div>{mapTxType(cell.getValue())}</div>,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Тип баланса',
        accessorKey: 'balanceType',
        filterVariant: 'multi-select',
        filterSelectOptions: balanceTypeLookup,
        Cell: ({ cell }) => <div>{BALANCE_TYPE_MESSAGES[cell.getValue()] || cell.getValue()}</div>,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Провайдер',
        accessorKey: 'externalProviderType',
        filterVariant: 'multi-select',
        filterSelectOptions: convertIntoArray(externalProviderType),
        Cell: ({ cell }) => <div>{externalProviderType[cell.getValue()] || cell.getValue()}</div>,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Сумма',
        accessorKey: 'amount',
        Cell: ({ row }) => formatAmount(row.original.type, row.original.amount),
        enableColumnFilterModes: false,
      },
      {
        header: 'Описание',
        accessorKey: 'description',
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Игра',
        accessorKey: 'gameId',
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'ID',
        accessorKey: '_id',
        enableColumnFilterModes: false,
      },
    ],
    [],
  );

  const defaultFilters = useMemo(() => {
    return columns.reduce((acc, column) => {
      const searchQueryFilter = searchQueryFilters[column.accessorKey];
      if (searchQueryFilter) {
        acc.push({
          id: column.accessorKey,
          value: searchQueryFilter,
        });
      }
      return acc;
    }, []);
  }, [searchQueryFilters]);

  const isPersonalTransactions = userId && userId !== '0';

  const classes = useStyles();
  const request = useAuthAPI();

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState(defaultFilters);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MIN_PAGE_SIZE,
  });

  const debouncedColumnFilters = useDebounce(columnFilters, 700);

  const getTransactions = async () => {
    try {
      setIsLoading(true);

      const sort = sorting[0];
      const orderQuery = sort
        ? { orderBy: sort?.id, orderDirection: sort?.desc ? 'desc' : 'asc' }
        : {};

      const filterByUserId = isPersonalTransactions ? { userId } : {};
      const filters = getFilters(columnFilters);

      URLHelper.replaceSearchParams(
        qs.stringify({ ...filters, page: pagination.pageIndex, pageSize: pagination.pageSize }),
      );

      const { data: remoteData } = await request(endpoints.transactions, {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        ...orderQuery,
        ...filters,
        ...filterByUserId,
      });
      setData(remoteData.data);
      setRowCount(remoteData.total);
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, [debouncedColumnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  return (
    <div>
      {userId !== '0' && <TransactionsStatsByDays userId={userId} />}
      <CreateTransactionDialog
        userId={userId}
        open={openCreateDialog}
        onHandleClose={(data) => {
          setOpenCreateDialog(false);
          if (data) getTransactions();
        }}
      />
      <ImportJackStatsDialog
        open={openImportStatsDialog}
        onHandleClose={() => {
          setOpenImportStatsDialog(false);
        }}
        onSuccess={() => {}}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>
                Управление транзакциями {isPersonalTransactions ? userId : undefined}
              </p>
              {isPersonalTransactions && user && (
                <>
                  <span className={classes.balance}>Баланс пользователя:</span>
                  <span>{user.balance.toFixed(2)}</span>
                </>
              )}
            </CardHeader>
            <CardBody>
              <MaterialReactTable
                columns={columns}
                data={data}
                getRowId={(row) => row._id}
                initialState={{
                  showColumnFilters: true,
                }}
                enableMultiSort={false}
                manualFiltering
                manualPagination
                manualSorting
                enableGlobalFilter={false}
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                enableColumnFilterModes
                localization={MRT_Localization_RU}
                enableToolbarInternalActions
                state={{
                  columnFilters,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  sorting,
                }}
                renderTopToolbar={({ table }) => {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box>
                        {isPersonalTransactions && (
                          <IconButton onClick={() => setOpenCreateDialog(true)}>
                            <AddIcon />
                          </IconButton>
                        )}
                        <IconButton onClick={() => setOpenImportStatsDialog(true)}>
                          <ReceiptIcon />
                        </IconButton>
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                      </Box>
                    </Box>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Transactions;
