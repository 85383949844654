import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import PaymentsTable from 'components/PaymentsTable';
import { RejectPayoutForm } from 'components/RejectPayout';
import { PaymentService } from 'services/api/payment';
import { useState, useCallback, useRef } from 'react';
import { errorService } from 'services/alert/services';
import ConfirmDialog from 'components/ConfirmDialog';
import { infoService } from 'services/alert/services';
import { UserProfileDialog } from 'components/UserProfile';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

const Payments = ({ type, status, unequalStatus, title, pageType }) => {
  const classes = useStyles();
  const [openRejectPayoutForm, setOpenRejectPayoutForm] = useState(false);
  const [openRecreatePayoutDialog, setOpenRecreatePayoutDialog] = useState(false);
  const [paymentNo, setPaymentNo] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isUserDialogOpen, setUserDialogOpen] = useState(false);
  const tableRef = useRef(null);

  const onRejectPayout = useCallback(async (paymentNo) => {
    setPaymentNo(paymentNo);
    setOpenRejectPayoutForm(true);
  }, []);

  const recreatePayout = async () => {
    try {
      await PaymentService.recreatePayout(paymentNo);
      tableRef.current.retry();
      infoService.sendInfo('Выплата успешно воссоздана');
    } catch (e) {
      errorService.sendError(e.response?.data?.message ?? e.message);
    } finally {
      onRecreatePayoutDialogClose();
    }
  };

  const onOpenRecreatePayoutDialog = useCallback((rowData) => {
    setPaymentNo(rowData._id);
    setOpenRecreatePayoutDialog(true);
  }, []);

  const onRecreatePayoutDialogClose = () => {
    setOpenRecreatePayoutDialog(false);
    setPaymentNo(null);
  };

  const onUserClick = useCallback(async (rowData) => {
    setSelectedUserId(rowData._id);
    setUserDialogOpen(true);
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>{title}</p>
            </CardHeader>
            <CardBody>
              <UserProfileDialog
                id={selectedUserId}
                open={isUserDialogOpen}
                onHandleClose={() => {
                  setUserDialogOpen(false);
                }}
                onVerificationUpdate={() => {
                  tableRef.current.retry();
                }}
              />
              <ConfirmDialog
                title="Вы уверены, что хотите пересоздать выплату?"
                open={openRecreatePayoutDialog}
                onClose={onRecreatePayoutDialogClose}
                onCancel={onRecreatePayoutDialogClose}
                onApprove={recreatePayout}
                cancelText="Отменить"
                approveText="Подтвердить"
              />
              <RejectPayoutForm
                open={openRejectPayoutForm}
                paymentNo={paymentNo}
                onHandleClose={() => {
                  setOpenRejectPayoutForm(false);
                }}
                onSuccess={() => {
                  tableRef.current.retry();
                }}
              />
              <PaymentsTable
                tableRef={tableRef}
                title={title}
                status={status}
                unequalStatus={unequalStatus}
                type={type}
                pageType={pageType}
                onRejectPayout={onRejectPayout}
                openRecreatePayoutDialog={onOpenRecreatePayoutDialog}
                onUserClick={onUserClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Payments;
