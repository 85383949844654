import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W1({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='w-01_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style />
        <linearGradient
          id='w-01_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#w-01_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='w-01_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={17.119}
            y1={37.757}
            x2={52.882}
            y2={37.757}
          >
            <stop offset={0} stopColor='#f7f5d2' />
            <stop offset={1} stopColor='#d7d4b7' />
          </linearGradient>
          <path
            fill='url(#w-01_svg__SVGID_2_)'
            d='M17.1 58.7V54h4.1v-3.8l4.7-4V29.3l-5.4-3.4v-9.1h6.1v4.1H32v-4.1h6v4.1h5.4v-4.1h6.1v9.1l-5.4 3.4v16.9l4.7 4V54h4.1v4.7z'
          />
          <linearGradient
            id='w-01_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35}
            y1={61.175}
            x2={35}
            y2={14.339}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M49.5 16.8v9.1l-5.4 3.4v16.9l4.7 4V54h4v4.7H17.1V54h4v-3.7l4.7-4v-17l-5.4-3.4v-9.1h6.1v4H32v-4h6v4h5.4v-4h6.1m2.5-2.5H40.9v4h-.4v-4h-11v4h-.4v-4H18v13l1.2.7 4.2 2.6V45l-3.9 3.3-.9.7v2.3h-4v9.9h40.8v-9.7h-4v-2.3l-.9-.7-3.9-3.5V30.7l4.2-2.6 1.2-.7V14.3z'
            fill='url(#w-01_svg__SVGID_3_)'
          />
          <g>
            <path
              d='M49.5 53.3v-3.4l-4.7-4V29.7l5.4-3.4V16.2h-7.4v4h-4v-4h-7.4v4h-4v-4H20v10.1l5.4 3.4v16.2l-4.7 4v3.4h-4v6.1h37.1v-6.1h-4.3zm-27.6-2.8h26.3v2.7H21.8l.1-2.7zm-.7-33h4.7v4h6.6v-4h4.7v4h6.6v-4h4.7v6.7H21.2v-6.7zm0 8h27.7l-4.8 3-.6.4H26.6l-.6-.4-4.8-3zm5.4 19.6V30.3h16.9v14.9H26.6zm-.5 1.8l.5-.4h16.9l.5.4 2.7 2.3H23.4l2.7-2.3zM52.2 58H17.8v-3.4H52.1V58z'
              fill='#231f20'
            />
          </g>
        </g>
      </svg>
    </SVGUniqueID>
  )
}
