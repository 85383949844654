import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { emitter } from 'shared/emitter';

const FullScreenView = () => {
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    emitter.on('fullscreen.image.open', (imageUrl) => {
      setOpen(true);
      setImageUrl(imageUrl);
    });
    emitter.on('fullscreen.image.close', () => {
      setOpen(false);
      setImageUrl('');
    });
  }, []);

  const onClose = () => {
    emitter.emit('fullscreen.image.close');
  };
  return <>{open && <Lightbox mainSrc={imageUrl} onCloseRequest={onClose} />}</>;
};

export default FullScreenView;
