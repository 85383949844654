import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class UserService {
  static async update(id, data) {
    const response = await api.put(`${endpoints.users}/${id}`, data);
    return response;
  }
  static async getUserProfile(id) {
    const response = await api.get(`${endpoints.users}/${id}`);
    return response;
  }
  static async rejectVerification(id, data) {
    const response = await api.put(`${endpoints.users}/reject-verification/${id}`, data);
    return response;
  }
  static async updateBlockedGames(id, sections, externalGames) {
    const response = await api.post(`${endpoints.users}/blocked-games/${id}`, {
      sections,
      externalGames,
    });
    return response;
  }
}
