import axios from 'axios';
import qs from 'qs';
import axiosRetry from 'axios-retry';
import _ from 'lodash';

import { endpointsRoot } from './endpoints';

import { errorService } from 'services/alert/services';

export const api = axios.create({
  baseURL: endpointsRoot,
  withCredentials: true,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = `Bearer ${JSON.parse(token)}`;

  return config;
});

axios.defaults.withCredentials = true;

axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 3000;
  },
});

export async function get(endpoint) {
  try {
    const response = await axios.get(endpoint, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    errorService.sendError(error.message);

    return { error: error };
  }
}

export async function post(endpoint, params) {
  const result = await axios({
    url: endpoint,
    method: 'post',
    data: qs.stringify(params),
    withCredentials: true,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });

  return result;
}

export async function doPost({ url, params }) {
  let response;
  try {
    response = await axios({
      method: 'post',
      url,
      data: qs.stringify(params),
      withCredentials: true,
    });

    return { result: response.data, error: false };
  } catch (error) {
    return {
      response: error.response.data,
      error: _.get(error, 'response.data.error', true),
    };
  }
}

export async function doGet({ url }) {
  try {
    const response = await axios.get(url, {
      withCredentials: true,
    });

    return { result: response.data, error: false };
  } catch (error) {
    return { error: _.get(error, 'response.data.error', true) };
  }
}
