import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';
import { BannerService } from '../../services/api/banner';
import CreateOrUpdateBannerModal from './create-or-update-banner-modal';
import { mapBannerType, mapRedirectType } from './utils';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const pageSize = 15;
const pageSizeOptions = [15, 20, 30, 40];

const useStyles = makeStyles(styles);

const Banners = () => {
  const classes = useStyles();
  const [isOpenModal, setOpenModal] = useState();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [banners, setBanners] = useState([]);

  const tableRef = useRef();

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      const { data } = await BannerService.getBanners();
      setBanners(data.data);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const onRowDelete = async (rowData) => {
    try {
      await BannerService.deleteBannerById(rowData._id);
      getBanners();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const onBannerCreate = async ({ button, ...rowData }) => {
    try {
      const validatedData = button?.backgroundColor || button?.borderColor ? button : null;
      const data = {
        ...rowData,
        button: validatedData,
        date: rowData.date ? new Date(rowData.date) : rowData.date,
      };
      await BannerService.createBanner(data);
      getBanners();
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      onModalClose();
    }
  };

  const onBannerUpdate = async ({ button, ...rowData }, prevData) => {
    try {
      const validatedData = button?.backgroundColor || button?.borderColor ? button : null;
      const data = { ...rowData, button: validatedData };
      await BannerService.updateBannerById(prevData._id, data);
      getBanners();
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      onModalClose();
    }
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <CreateOrUpdateBannerModal
        open={isOpenModal}
        handleClose={onModalClose}
        onCreate={onBannerCreate}
        onUpdate={onBannerUpdate}
        banner={selectedBanner}
        mode={selectedBanner ? 'update' : 'create'}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление баннерами</p>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title="Баннеры"
                tableRef={tableRef}
                localization={{
                  body: {
                    editTooltip: 'Редактировать',
                    editRow: {
                      deleteText: 'Вы уверены что хотите удалить баннер?',
                    },
                  },
                  toolbar: { searchPlaceholder: 'Поиск' },
                  pagination: { labelRowsSelect: 'cтрок' },
                  header: {
                    actions: 'Действия',
                  },
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Создать баннер',
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedBanner(null);
                      setOpenModal(true);
                    },
                  },
                  {
                    icon: 'edit',
                    tooltip: 'Обновить баннер',
                    onClick: (_, rowData) => {
                      setSelectedBanner(rowData);
                      setOpenModal(true);
                    },
                  },
                ]}
                columns={[
                  {
                    title: 'ID',
                    field: '_id',
                    editable: false,
                  },
                  {
                    title: 'Текст',
                    field: 'text',
                    editable: false,
                  },
                  {
                    title: 'Тип',
                    field: 'type',
                    editable: false,
                    render: (rowData) => mapBannerType(rowData.type),
                  },
                  {
                    title: 'Цвет фона',
                    field: 'backgroundColor',
                    editable: false,
                  },
                  {
                    title: 'Изоб. фона',
                    field: 'image',
                    editable: false,
                    render: (rowData) => {
                      return rowData.image.url || rowData.image.filename;
                    },
                  },
                  {
                    title: 'Дата',
                    field: 'date',
                    editable: false,
                  },
                  {
                    title: 'Тип редиректа',
                    field: 'redirectType',
                    editable: false,
                    render: (rowData) => mapRedirectType(rowData.redirectType),
                  },
                  {
                    title: 'Редирект в ...',
                    field: 'redirectTo',
                    editable: false,
                  },
                  {
                    title: 'Порядок',
                    field: 'order',
                  },
                  {
                    title: 'Статус',
                    field: 'enabled',
                    render: (rowData) => (rowData.enabled ? 'Включен' : 'Отключен'),
                  },
                ]}
                options={{
                  filtering: false,
                  debounceInterval: 700,
                  search: false,
                  pageSize,
                  pageSizeOptions,
                }}
                data={banners}
                editable={{
                  onRowDelete,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Banners;
