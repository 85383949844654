import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W1({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 70 70'
      >
        <g clipPath='url(#clip0_8)'>
          <path
            fill='url(#paint0_linear_8)'
            d='M10 69.5A9.56 9.56 0 01.5 60V10A9.56 9.56 0 0110 .5h50a9.56 9.56 0 019.5 9.5v50a9.56 9.56 0 01-9.5 9.5H10z'
          />
          <path
            fill='#fff'
            d='M60 1a9 9 0 019 9v50a9 9 0 01-9 9H10a9 9 0 01-9-9V10a9 9 0 019-9h50zm0-1H10A10 10 0 000 10v50a10 10 0 0010 10h50a10 10 0 0010-10V10A10 10 0 0060 0z'
          />
          <path
            fill='url(#paint1_linear_8)'
            d='M35.22 44.77c5.396 0 9.77-4.374 9.77-9.77s-4.374-9.77-9.77-9.77-9.77 4.374-9.77 9.77 4.374 9.77 9.77 9.77z'
          />
          <path
            fill='url(#paint2_linear_8)'
            stroke='#0B0A09'
            d='M35.22 42.06a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_8'
            x1='35'
            x2='35'
            y1='69.5'
            y2='.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#BCC5C7' />
            <stop offset='.65' stopColor='#E6E8E9' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_8'
            x1='35.225'
            x2='35.222'
            y1='44.774'
            y2='25.234'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_8'
            x1='35.22'
            x2='35.22'
            y1='28.44'
            y2='41.56'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <clipPath id='clip0_8'>
            <path fill='#fff' d='M0 0h70v70H0z' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
