import React, { useState, useEffect } from 'react';

const DIGITS = [8, 7, 6, 5, 4, 3, 2, 1];

export const VNotation = ({ orientation, borderWidth }) => {
  const [notation, setNotation] = useState([]);

  useEffect(() => {
    setNotation(orientation[0] === 'w' ? DIGITS : DIGITS.slice().reverse());
  }, [orientation]);

  return (
    <div
      style={{
        width: borderWidth,
        top: 12,
        bottom: 12,
        left: 0,
        height: '100hw',
        alignItems: 'center',
        fontSize: 10,
        position: 'absolute',
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {notation.map((n) => (
        <div key={n}>{n}</div>
      ))}
    </div>
  );
};
