export const tournamentType = {
  REGULAR: 'regular',
  JACKPOT: 'jackpot',
};

export const isType = (type, compareTo) => {
  return type === compareTo;
};

export const isMode = (mode, compareTo) => {
  return mode === compareTo;
};

export const modes = {
  update: 'update',
  create: 'create',
};
