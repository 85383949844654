import React from 'react'
import miniPieces from './images/mini-pieces'

function shortColor(color) {
  return color.charAt(0)
}

export const KilledPieces = ({ killedPieces, color }) => {
  const kPieces =
    killedPieces &&
    Object.keys(killedPieces).reduce((acc, key) => {
      const total = killedPieces[key]
      const p = shortColor(color === 'white' ? 'black' : 'white') + key

      for (var i = 0; i < total; i++) {
        acc.push(miniPieces[p])
      }
      return acc
    }, [])

  const visibleFigures = 30

  const shotKilledPieces_ =
    killedPieces && kPieces.length > visibleFigures
      ? [...kPieces.slice(kPieces.length - visibleFigures, kPieces.length)]
      : kPieces

  const shotKilledPieces = shotKilledPieces_
    ? shotKilledPieces_.map((p, i) => (
        <div
          key={i}
          style={{
            transform: 'scale(0.6)',
            position: 'absolute',
            height: 16,
            width: 16,
            left: -8 + i * 10
          }}
        >
          {p()}
        </div>
      ))
    : kPieces

  return <React.Fragment> {killedPieces && shotKilledPieces}</React.Fragment>
}
