import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function B2({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='b-02_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style>{'.b-02_svg__st9{fill:#d4d1b4}'}</style>
        <linearGradient
          id='b-02_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#b-02_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='b-02_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={10.702}
            y1={35.726}
            x2={59.298}
            y2={35.726}
          >
            <stop offset={0} stopColor='#3a3a3f' />
            <stop offset={1} stopColor='#040408' />
          </linearGradient>
          <path
            d='M59.3 33.4c0-6.2-5-11.1-11.1-11.1-3.4 0-6.4 1.5-8.5 3.9v-.5c0-2.4-1.8-4.3-4-4.7v-4.1H39v-1.3h-3.4v-3.4h-1.3v3.4H31v1.3h3.4V21c-2.3.3-4 2.3-4 4.7v.5c-2-2.4-5.1-3.9-8.5-3.9-6.2 0-11.1 5-11.1 11.1 0 4 2.1 7.5 5.3 9.5 0 0 3.4 2.3 3.4 6.4 0 3.9 0 4-.6 6.4-.1.1-.1.2-.1.4 0 1.9 7.3 3.4 16.2 3.4 8.9 0 16.2-1.5 16.2-3.4 0-.1 0-.2-.1-.4-.6-2.4-.6-2.5-.6-6.4 0-4 3.4-6.4 3.4-6.4 3.3-2 5.4-5.5 5.4-9.5z'
            fill='url(#b-02_svg__SVGID_2_)'
          />
          <linearGradient
            id='b-02_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35}
            y1={60.849}
            x2={35}
            y2={10.603}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M35.7 12.1v3.4H39v1.3h-3.4v4.1c2.3.3 4 2.3 4 4.7v.5c2-2.4 5.1-3.9 8.5-3.9 6.2 0 11.1 5 11.1 11.1 0 4-2.1 7.5-5.3 9.5 0 0-3.4 2.3-3.4 6.4 0 3.9 0 4 .6 6.4.1.1.1.2.1.4 0 1.9-7.3 3.4-16.2 3.4-8.9 0-16.2-1.5-16.2-3.4 0-.1 0-.2.1-.4.6-2.4.6-2.5.6-6.4 0-4-3.4-6.4-3.4-6.4-3.2-2-5.3-5.5-5.3-9.5 0-6.2 5-11.1 11.1-11.1 3.4 0 6.4 1.5 8.5 3.9v-.5c0-2.4 1.8-4.3 4-4.7v-4.1H31v-1.3h3.4v-3.4h1.3m1.5-1.5h-4.3V14h-3.4v4.3h3.4v1.4c-1.6.6-2.9 1.8-3.5 3.4-2.1-1.6-4.7-2.4-7.4-2.4-7 0-12.6 5.7-12.6 12.6 0 3.5 1.5 6.8 4 9.2l-.4.1 2.2 1.5S18 46 18 49.2c0 3.7 0 3.9-.5 5.9-.1.3-.2.6-.2.8 0 2.3 3.4 3.3 5.9 3.9 3.2.7 7.3 1 11.8 1s8.6-.4 11.8-1c2.5-.5 5.9-1.5 5.9-3.9 0-.3-.1-.6-.2-.8-.5-2-.5-2.2-.5-5.9 0-3.2 2.8-5.1 2.8-5.1l2.2-1.5-.2-.1c2.5-2.4 4-5.7 4-9.2 0-7-5.7-12.6-12.6-12.6-2.7 0-5.3.9-7.4 2.4-.7-1.5-1.9-2.8-3.5-3.4v-1.4h3.2V14h-3.4v-3.4h.1z'
            fill='url(#b-02_svg__SVGID_3_)'
          />
          <g>
            <path
              className='b-02_svg__st9'
              d='M35 24.2c.8 0 1.4.6 1.4 1.4 0 .5-.6 2-1.4 3.7-.8-1.7-1.3-3.1-1.4-3.6 0-.9.6-1.5 1.4-1.5m0-2c-1.9 0-3.4 1.5-3.4 3.4s3.4 8.1 3.4 8.1 3.4-6.2 3.4-8.1c0-1.9-1.5-3.4-3.4-3.4z'
            />
          </g>
          <g>
            <path
              className='b-02_svg__st9'
              d='M21.2 26.3c5.9 0 8 4 8.6 5.7.2.7.6 1.4 1 2.2.7 1.3 1.5 2.8 1.5 3.9v3.4c-.7-.1-1.6-.1-2.6-.1-3.2 0-6.1.5-8.7 1.5-2.5-1.8-6.9-5.7-6.9-9.4.1-1 .9-7.2 7.1-7.2m0-2c-8.5 0-9.1 9.2-9.1 9.2 0 6.3 8.7 11.8 8.7 11.8 3.1-1.4 6.3-1.7 8.9-1.7 2.7 0 4.6.4 4.6.4v-5.7c.1-2.4-2-5.1-2.6-6.9-.9-2.7-3.7-7.1-10.5-7.1z'
            />
          </g>
          <g>
            <path
              className='b-02_svg__st9'
              d='M48.5 26.2c1.4 0 3.9.3 5.6 2.5 1.1 1.5 2.1 3.8 1.7 5.7-.6 3.3-4 7-6.8 8.6-.2-.1-.4-.2-.7-.3-1.9-.6-4-1-6.2-1.2-.6 0-1.3-.1-1.9-.1-.8 0-1.6 0-2.4.1v-3.4c0-1.4.9-3.1 1.7-4.7.4-.7.7-1.4 1-2 .7-1.5 1.7-2.8 3-3.6 1.4-1 3.2-1.6 5-1.6m0-2c-2.2 0-4.4.6-6.2 1.8-1.6 1.1-2.9 2.7-3.7 4.5-1 2.3-3 5-2.9 7.6v5.7c1.5-.3 3-.4 4.4-.4.6 0 1.2 0 1.8.1 1.9.1 3.9.5 5.7 1.1.8.3 1 .5 1.4.5.2 0 .4-.1.8-.3 3.5-1.8 7.3-6.2 8-10.1.4-2.5-.6-5.3-2.1-7.2-1.8-2.3-4.5-3.3-7.2-3.3z'
            />
          </g>
          <path
            className='b-02_svg__st9'
            d='M49.8 55.8c-4.4-1.2-9.3-1.9-14.6-1.9-5.3 0-10.4.7-14.8 2-.1 0-.1-.1-.1-.1.2-.7.3-1.2.4-1.7 4.5-1.2 9.5-1.8 14.6-1.8 4.9 0 9.6.6 14 1.7.1 0 .1 0 .2.1 0 .4.1 1 .3 1.7z'
          />
          <path d='M19.5 50v.8' fill='none' />
          <path
            className='b-02_svg__st9'
            d='M49.2 48.6v1.8c-4.2-1.1-9-1.7-14-1.7-5.2 0-10 .7-14.4 1.9v-1.2-.6c4.5-1.2 9.3-1.8 14.4-1.8 4.9-.1 9.6.4 14 1.6z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
