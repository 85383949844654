import { Subject } from "rxjs";

const errorSubject = new Subject();
const infoSubject = new Subject();
const warnSubject = new Subject();


export const errorService = {
  sendError: error => errorSubject.next(error),
  getError: () => errorSubject.asObservable()
};

export const infoService = {
  sendInfo: info => infoSubject.next(info),
  getInfo: () => infoSubject.asObservable()
};


export const warnService = {
  sendWarn: warn => warnSubject.next(warn),
  getWarn: () => warnSubject.asObservable()
};