import React, { useCallback, useEffect, useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import UsersTable from 'components/UsersTable';

import { usePostAPI } from 'hooks/use-post-api';
import { usePutAPI } from 'hooks/use-put-api';

import { UserProfileDialog } from 'components/UserProfile';

import { errorService, infoService, warnService } from 'services/alert/services';

import endpoints from 'api/endpoints';
import { UpdateUserSecurityForm } from 'components/UserSecurity';
import { UserBlockForm } from '../../components/UserBlockForm/index';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

function Users() {
  const [currentUser, setCurrentUser] = useState();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateSecurityForm, setOpenUpdateSecurityForm] = useState(false);
  const [openUserBlockForm, setOpenUserBlockForm] = useState(false);

  const [
    { data: createUserData, isLoading: isCreateUserLoading, isError: isCreateUserError },
    doCreateUser,
  ] = usePostAPI({});
  const [
    { data: updateUserData, isLoading: isUpdateUserLoading, isError: isUpdateUserError },
    doUpdateUser,
  ] = usePutAPI({});

  const tableRef = useRef(null);

  useEffect(() => {
    if (isCreateUserError)
      errorService.sendError('Невозможно создать пользователя: ' + createUserData.response.message);
  }, [isCreateUserError]);

  useEffect(() => {
    if (isUpdateUserError)
      errorService.sendError(
        'Невозможно изменить пользователя: ' + updateUserData.response.message,
      );
  }, [isUpdateUserError]);

  const onRowAdd = useCallback((newData) => {
    return new Promise((resolve, reject) => {
      doCreateUser({
        url: endpoints.users,
        params: newData,
        onSuccess: () => {
          infoService.sendInfo('Пользователь был успешно создан!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }, []);

  const onRowUpdate = useCallback((newData, oldData) => {
    return new Promise((resolve, reject) => {
      const diff = Object.keys(newData)
        .filter((o) => o !== 'tableData')
        .reduce((acc, key) => {
          if (oldData[key] !== newData[key]) {
            acc[key] = newData[key];
          }
          return acc;
        }, {});
      if (Object.keys(diff).length === 0) return resolve();
      doUpdateUser({
        url: `${endpoints.users}/${oldData._id}`,
        params: diff,
        onSuccess: () => {
          infoService.sendInfo('Пользователь был успешно изменен!');

          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }, []);

  const onRowDelete = useCallback((oldData) => {
    return new Promise((resolve, reject) => {
      doUpdateUser({
        url: `${endpoints.users}/${oldData._id}`,
        params: { deleted: true },
        onSuccess: () => {
          warnService.sendWarn('Пользователь был успешно удален!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }, []);

  const onUserClickHandler = useCallback((event, rowData) => {
    if (rowData.blocked) {
      return new Promise((resolve, reject) => {
        doUpdateUser({
          url: `${endpoints.users}/${rowData._id}`,
          params: { blocked: !rowData.blocked, blockReason: null },
          onSuccess: () => {
            warnService.sendWarn(
              `Пользователь был успешно ${rowData.blocked ? 'разблокирован' : 'заблокирован'}!`,
            );
            tableRef.current.retry();
            resolve();
          },
          onError: () => {
            reject();
          },
        });
      });
    } else {
      setCurrentUser(rowData);
      setOpenUserBlockForm(true);
    }
  }, []);

  const onBlockChatClickHandler = useCallback(async (event, rowData) => {
    return new Promise((resolve, reject) => {
      doUpdateUser({
        url: `${endpoints.users}/${rowData._id}`,
        params: { isChatBlocked: !rowData?.gameSettings?.isChatBlocked },
        onSuccess: () => {
          resolve();
          tableRef.current.retry();
        },
        onError: () => {
          reject();
        },
      });
    });
  }, []);

  const classes = useStyles();
  return (
    <div>
      <UserProfileDialog
        id={currentUser?._id}
        open={openCreateDialog}
        onHandleClose={() => {
          setOpenCreateDialog(false);
        }}
        onVerificationUpdate={() => {
          tableRef.current.retry();
        }}
      />
      <UpdateUserSecurityForm
        open={openUpdateSecurityForm}
        profile={currentUser}
        onHandleClose={() => {
          setOpenUpdateSecurityForm(false);
        }}
        onUpdate={() => {
          tableRef.current.retry();
        }}
      />
      <UserBlockForm
        open={openUserBlockForm}
        profile={currentUser}
        onHandleClose={() => {
          setOpenUserBlockForm(false);
          setCurrentUser(null);
        }}
        onSuccess={() => {
          tableRef.current.retry();
        }}
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление пользовательскими аккаунтами</p>
            </CardHeader>
            <CardBody>
              <UsersTable
                tableRef={tableRef}
                setCurrentUser={setCurrentUser}
                setOpenCreateDialog={setOpenCreateDialog}
                setOpenUpdateSecurityForm={setOpenUpdateSecurityForm}
                onUserClickHandler={onUserClickHandler}
                onBlockChatClickHandler={onBlockChatClickHandler}
                onRowAdd={onRowAdd}
                onRowUpdate={onRowUpdate}
                onRowDelete={onRowDelete}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Users;
