export const PaymentType = {
  topUp: 'TOP_UP',
  withdraw: 'WITHDRAW',
  withdrawRequest: 'WITHDRAW_REQUEST',
};

export const PaymentTransactionStatus = {
  placeholder: 'placeholder',
  created: 'created',
  processed: 'processed',
  refunded: 'refunded',
  failedToCreate: 'failedToCreate',
  paymentRejected: 'paymentRejected',
  waitingForApproval: 'waitingForApproval',
  rejectedByAdmin: 'rejectedByAdmin',
  approved: 'approved',
  canceled: 'canceled',
};

export const Currency = {
  RUB: 'RUB',
  EUR: 'EUR',
  UAH: 'UAH',
  INR: 'INR',
  KZT: 'KZT',
  USD: 'USD',
  BTC: 'BTC',
  USDT: 'USDT',
  USDT_TRC20: 'USDT (TRC20)',
  ETH: 'ETH',
  BUSD: 'BUSD',

  UZS: 'UZS',
  AZN: 'AZN',
  KRW: 'KRW',
  KGS: 'KGS',
};

export const paymentMethodType = {
  visaEurope: 'visaEurope',
  visaRussia: 'visaRussia',
  visaUkraine: 'visaUkraine',
  visaKZT: 'visaKZT',
  visaUSD: 'visaUSD',
  indiaPayTM: 'indiaPayTM',
  indiaPhonePE: 'indiaPhonePE',
  indiaUPI: 'indiaUPI',

  cryptoBitcoin: 'cryptoBitcoin',
  cryptoTether: 'cryptoTether',
  cryptoETH: 'cryptoETH',
  cryptoUSDT_TRC20: 'cryptoUSDT_TRC20',
  cryptoBUSD: 'cryptoBUSD',
  indiaImps: 'indiaImps',

  CardToCardSberbank: "CardToCardSberbank",
  CardToCardTinkoff: "CardToCardTinkoff",
  CardToCardInterbank: "CardToCardInterbank",
  Sbp: "Sbp",
  OCT: "OCT",

  cryptoUSD: "cryptoUSD",

  eurasianKZT: "eurasianKZT",
  jusanKZT: "jusanKZT",

  raiffeisenbankRUB: "raiffeisenbankRUB",
  sberbankRUB: "sberbankRUB",
  tinkoffRUB: "tinkoffRUB",
  sbpRUB: "sbpRUB",

  humoUZS: "humoUZS",
  cardUZS: "cardUZS",

  cassyINR: "cassyINR",

  cassyKZT: "cassyKZT",
  cassyRUB: "cassyRUB",

  cassyUAH: "cassyUAH",
  cassyUZS: 'cassyUZS',
  cassyAZN: 'cassyAZN',
  cassyKRW: 'cassyKRW',
  cassyKGS: 'cassyKGS',
};

export const paymentTypeTranslations = {
  [PaymentType.topUp]: 'Пополнение',
  [PaymentType.withdraw]: 'Вывод',
};

export const currencies = {
  [Currency.RUB]: 'Рубль',
  [Currency.EUR]: 'Евро',
  [Currency.UAH]: 'Гривна',
  [Currency.INR]: 'Рупия',
  [Currency.KZT]: 'Тенге',
  [Currency.USD]: 'Доллар',
  [Currency.BTC]: 'BTC',
  [Currency.USDT]: 'USDT(ERC20)',
  [Currency.USDT_TRC20]: 'USDT(TRC20)',
  [Currency.ETH]: 'ETH',
  [Currency.BUSD]: 'BUSD',

  [Currency.UZS]: 'Сум',
  [Currency.AZN]: 'Манат',
  [Currency.KRW]: 'Вона',
  [Currency.KGS]: 'Сом',
};

export const paymentProviders = {
  interkassa: 'Интеркасса',
  crypto: 'Крипта',
  cypix: 'Cypix',
  p2p: 'P2P',
  cassy: 'Cassy',
};
