import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import { TransactionService } from 'services/api/transaction';
import { errorService, infoService } from 'services/alert/services';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bolder',
  },
});

export default function ImportJackStatsDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (data) => {
    props.onHandleClose(data);
    setOpen(false);
  };

  const handleSubmit = async (values, actions) => {
    const formData = new FormData();

    formData.append('files', values.file);
    try {
      const { data } = await TransactionService.importJackStats(formData);
      actions.setFieldValue('stats', data);
      infoService.sendInfo('Успешно загружено');
      props.onSuccess();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth="xs">
        <DialogTitle id="form-dialog-title">Загрузить JackPoker статистику</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ file: null, stats: null }}
            validationSchema={Yup.object().shape({
              file: Yup.mixed().required('Загрузите файл'),
            })}
            onSubmit={handleSubmit}
            render={({ values, errors, isSubmitting, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl component="fieldset" fullWidth>
                    {values.stats && !isSubmitting && (
                      <>
                        <div>
                          <Grid item xs={12}>
                            <span className={classes.bold}>Oбщий:</span> {values.stats?.total ?? 0}
                          </Grid>
                          <Grid item xs={12}>
                            <span className={classes.bold}>Успешно:</span>{' '}
                            {values.stats?.success ?? 0}
                          </Grid>
                          <Grid item xs={12}>
                            <span className={classes.bold}>
                              Количество не найденных пользователей:
                            </span>{' '}
                            {values.stats?.noUser ?? 0}
                          </Grid>
                          <Grid item xs={12}>
                            <span className={classes.bold}>С ошибками:</span>{' '}
                            {values.stats?.withErrors ?? 0}
                          </Grid>
                          <Grid item xs={12}>
                            <span className={classes.bold}>Обработано:</span>{' '}
                            {values.stats?.processed ?? 0}
                          </Grid>
                          <Grid item xs={12}>
                            <span className={classes.bold}>Пропущено:</span>{' '}
                            {values.stats?.skipped ?? 0}
                          </Grid>
                        </div>
                        <br />
                      </>
                    )}
                    <input
                      onChange={(e) => {
                        if (!e.target.files.length) return;
                        setFieldValue('file', e.target.files[0]);
                      }}
                      id="raised-button-file"
                      style={{ width: '100%', display: 'none' }}
                      type="file"
                    />
                    <label htmlFor="raised-button-file" style={{ width: '100%' }}>
                      <Button variant="outlined" component="span" size="large" fullWidth>
                        {values.file?.name ?? 'Upload'}
                      </Button>
                    </label>
                    <div style={{ color: 'red', fontSize: 12 }}>{errors.file}</div>
                  </FormControl>

                  <DialogActions>
                    <Button
                      onClick={() => handleClose()}
                      color="secondary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Отмена
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      Создать
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
