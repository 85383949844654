import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class ReferralProgramService {
  static async getAll() {
    const data = await api.get(endpoints.referralPrograms);
    return data;
  }
  static async insertOne(params) {
    const data = await api.post(endpoints.referralPrograms, params);
    return data;
  }
  static async deleteById(id) {
    const data = await api.delete(`${endpoints.referralPrograms}/${id}`);
    return data;
  }
  static async updateById(id, params) {
    const data = await api.put(`${endpoints.referralPrograms}/${id}`, params);
    return data;
  }
}
