
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Wrapper from 'components/Wrapper';
import { ProvideAuth } from 'hooks/use-auth';
import Admin from 'layouts/Admin.js';
import RTL from 'layouts/RTL.js';
import { Login } from 'views/Login';
import FullScreenView from 'components/FullScreenView';

import 'assets/css/material-dashboard-react.css?v=1.9.0';
import 'assets/css/lightbox.css';
import 'react-image-lightbox/style.css';
import 'swiper/swiper.min.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { ProvideStore } from './hooks/use-store';


const root = createRoot(document.getElementById('root')!);

root.render(
  <ProvideAuth>
    <BrowserRouter>
      <ProvideStore>
        <Wrapper>
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/rtl" component={RTL} />
            <Route path="/login" component={Login} />

            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </Wrapper>
      </ProvideStore>
    </BrowserRouter>
    <FullScreenView />
  </ProvideAuth>,
);
