import React, { useState } from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import { dialogStyles } from './styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Select } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { emptyAction, notificationActionNames, notificationClickTypes } from './constants';
import { mapActionTypeValue, mapActionName } from './utils';

const CreateNotificationModal = ({ open, handleClose, onSubmit }) => {
  const styles = dialogStyles();
  const [isLoading, setLoading] = useState(false);

  const onActionCreate = (values, setFieldValue) => {
    const actions = values.actions;
    setFieldValue('actions', [...actions, emptyAction]);
  };

  const onActionRemove = (values, setFieldValue) => {
    const actions = values.actions;
    setFieldValue('actions', actions.slice(0, -1));
  };

  const submitHandler = async (values, actions) => {
    setLoading(true);
    await onSubmit(values, actions);
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">Создать уведомление</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              title: { ru: '', en: '' },
              description: {
                ru: '',
                en: '',
              },
              actions: [],
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.object().shape({
                ru: Yup.string().required('Введите заголовок RU'),
                en: Yup.string().required('Введите заголовок EN'),
              }),
              description: Yup.object().shape({
                ru: Yup.string().required('Введите описание RU'),
                en: Yup.string().required('Введите описание EN'),
              }),
              actions: Yup.array().of(
                Yup.object().shape({
                  tagType: Yup.string().default('button'),
                  actionType: Yup.string().oneOf(Object.values(notificationClickTypes)),
                  actionProperties: Yup.object().shape({
                    actionName: Yup.string(),
                    tagLabel: Yup.string(),
                    path: Yup.string(),
                    link: Yup.string(),
                  }),
                }),
              ),
            })}
            onSubmit={submitHandler}
            validateOnChange
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              errors,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.nickname && touched.nickname)}
                      name="nickname"
                      autoFocus
                      margin="dense"
                      label="Пользователь"
                      value={values.nickname}
                      placeholder="Введите никнейм"
                    />
                    <div className={styles.errorText}>
                      {touched.nickname ? errors.nickname : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title?.ru && touched.title?.ru)}
                      name="title.ru"
                      autoFocus
                      margin="dense"
                      label="Заголовок RU"
                      value={values.title.ru}
                      placeholder="Введите заголовок RU"
                    />
                    <div className={styles.errorText}>
                      {touched.title?.ru ? errors.title?.ru : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title?.en && touched.title?.en)}
                      name="title.en"
                      autoFocus
                      margin="dense"
                      label="Заголовок EN"
                      value={values.title?.en}
                      placeholder="Введите заголовок EN"
                    />
                    <div className={styles.errorText}>
                      {touched.title?.en ? errors.title?.en : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.description?.ru && touched.description?.ru)}
                      name="description.ru"
                      autoFocus
                      margin="dense"
                      label="Описание RU"
                      value={values.description?.ru}
                      placeholder="Введите описание RU"
                      multiline
                      minRows={5}
                    />
                    <div className={styles.errorText}>
                      {touched.description?.ru ? errors.description?.ru : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.description?.en && touched.description?.en)}
                      name="description.en"
                      autoFocus
                      margin="dense"
                      label="Описание EN"
                      value={values.description.en}
                      placeholder="Введите описание EN"
                      multiline
                      minRows={5}
                    />
                    <div className={styles.errorText}>
                      {touched.description?.en ? errors.description?.en : ''}
                    </div>
                  </FormControl>
                  <div className={styles.actionsWrapper}>
                    {values.actions.map((action, i) => (
                      <FormControl className={styles.formControl} size="small" key={i}>
                        <InputLabel id="demo-select-small">Тип действия</InputLabel>
                        <Select
                          value={values.actions[i].actionType}
                          label="Тип действия"
                          name={`actions.${i}.actionType`}
                          onChange={handleChange}
                          displayEmpty
                        >
                          {Object.entries(notificationClickTypes).map(([_, clickTypeValue]) => (
                            <MenuItem key={clickTypeValue} value={clickTypeValue}>
                              {mapActionTypeValue(clickTypeValue)}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`actions.${i}.actionProperties.tagLabel`}
                          autoFocus
                          margin="dense"
                          label="Текст кнопки"
                          value={values.actions[i]?.actionProperties?.tagLabel}
                          placeholder="Путь на перевод"
                        />
                        {action.actionType === notificationClickTypes.internal_redirect && (
                          <TextField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`actions.${i}.actionProperties.path`}
                            autoFocus
                            margin="dense"
                            label="Путь"
                            value={values.actions[i]?.actionProperties?.path}
                            placeholder="Путь"
                          />
                        )}
                        {action.actionType === notificationClickTypes.external_redirect && (
                          <TextField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`actions.${i}.actionProperties.link`}
                            autoFocus
                            margin="dense"
                            label="Ссылка"
                            value={values.actions[i]?.actionProperties?.link}
                            placeholder="Путь"
                          />
                        )}
                        {action.actionType === notificationClickTypes.custom_action && (
                          <Select
                            value={values.actions[i].actionProperties?.actionName ?? ''}
                            label="Тип действия"
                            name={`actions.${i}.actionProperties.actionName`}
                            onChange={handleChange}
                            displayEmpty
                            style={{ marginTop: 8 }}
                          >
                            {Object.entries(notificationActionNames).map(([_, actionName]) => (
                              <MenuItem key={actionName} value={actionName}>
                                {mapActionName(actionName)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    ))}
                  </div>
                  <FormControl className={styles.actionButtons} size="small">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onActionCreate(values, setFieldValue)}
                      className={styles.actionButton}
                    >
                      + Действие
                    </Button>
                    {values.actions.length > 0 && (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => onActionRemove(values, setFieldValue)}
                        className={styles.actionButton}
                      >
                        - Действие
                      </Button>
                    )}
                  </FormControl>
                  <DialogActions>
                    <Button color="primary" variant="contained" type="submit" disabled={isLoading}>
                      Создать
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateNotificationModal;
