import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function B5({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='b-05_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style>{'.b-05_svg__st9{fill:#d4d1b4}'}</style>
        <linearGradient
          id='b-05_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#b-05_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='b-05_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={11.188}
            y1={36.02}
            x2={58.813}
            y2={36.02}
          >
            <stop offset={0} stopColor='#3a3a3f' />
            <stop offset={1} stopColor='#040408' />
          </linearGradient>
          <path
            d='M11.6 40.5s6.1-10.1 6.1-10.8 0-3.4.7-4.7 2.7-3.4 2.7-3.4v-2s-1.3-6.1-.7-6.7 6.1 2.7 6.7 4c0 0 1.3-2.7 2.7-3.4s4 4.7 4 4.7 20.9-1.3 23.6 22.3c0 0 1.3 11.5 1.3 18.9H24.4v-4s0-3.4 6.1-8.8 4.7-8.8 4.7-8.8-4 2.7-8.1 3.4l-2 2s-.7 6.1-6.1 5.4c0 0-1.3-1.3-2.7 0 0 0-6.8-1.4-4.7-8.1z'
            fill='url(#b-05_svg__SVGID_2_)'
          />
          <linearGradient
            id='b-05_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={34.999}
            y1={60.849}
            x2={34.999}
            y2={11.19}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M20.6 12.7c1.3 0 5.8 2.9 6.5 4.1 0 0 1.3-2.7 2.7-3.4.1 0 .2-.1.3-.1 1.4 0 3.8 4.8 3.8 4.8h.6c3.5 0 20.6 1.1 23 22.3 0 0 1.3 11.5 1.3 18.9H24.4v-4s0-3.4 6.1-8.8 4.7-8.8 4.7-8.8-4 2.7-8.1 3.4l-2 2s-.6 5.5-5.3 5.5c-.3 0-.5 0-.8-.1 0 0-.6-.6-1.4-.6-.4 0-.8.1-1.3.6 0 0-6.7-1.3-4.7-8.1 0 0 6.1-10.1 6.1-10.8 0-.7 0-3.4.7-4.7.7-1.3 2.7-3.4 2.7-3.4v-2s-1.3-6.1-.7-6.7c0-.1.1-.1.2-.1m0-1.5c-.7 0-1.1.3-1.3.5-.5.5-1.2 1.2.2 8V21c-.7.7-2 2.2-2.5 3.3-.5 1.1-.8 2.8-.8 5.2-.5 1.1-3.3 6-5.9 10.3l-.1.2h-.1c-.7 2.4-.6 4.6.5 6.4 1.7 2.9 5.2 3.6 5.4 3.6l.8.2.6-.6.2-.2c.1 0 .3.1.4.2l.4.4h.5c.3 0 .7.1 1 .1 4.4 0 6.2-3.8 6.7-6.2l1.3-1.3c2.1-.4 4-1.3 5.6-2-.5 1.2-1.6 2.9-3.9 4.9-6.4 5.7-6.6 9.5-6.6 9.9v5.5h37.4v-1.5c0-7.4-1.3-18.6-1.4-19.1-2.5-21.9-20-23.5-24.2-23.6-1.7-3.2-3.3-4.8-4.7-4.8-.3 0-.6.1-.9.2-.9.4-1.7 1.4-2.3 2.3-2-1.6-4.9-3.2-6.3-3.2z'
            fill='url(#b-05_svg__SVGID_3_)'
          />
          <path
            className='b-05_svg__st9'
            d='M34.5 20.2l.7 2s15.5-2 16.9 22.9V58h3.4V45.2c-.1 0-.1-24.3-21-25zM36.5 29s.5 3.2-.8 4.9c-.4.5-.6 1.2-.4 1.9.1.4.3.7.5.7.7 0 2 .7 2-7.4h-1.3zM21 43.2s-.7 2-2 2c0 0 0 2 1.3.7s2.7-2.7.7-2.7zM15.6 40.5s-2 2 .7 2l1.3-1.3c0-.1.7-2.8-2-.7zM25.7 24.3l-4 4s0 2.7 1.3.7h.3c.3.4.8.7 1.3.7.9 0 1.7-.8 1.7-1.7 0-.6-.2-1.4-.7-1.7l.7-.7c.1 0 2.1-1.3-.6-1.3z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
