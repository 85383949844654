import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  dialog: {
    zIndex: 10000,
  },
  form: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
}));
