export const externalGameTypes = {
  slots: 'Слоты',
  liveDealer: 'Live Dealer',
  sportsBetting: "Sports Betting"
};

export const externalGameProviderTypes = {
  algNet: 'AlgNet',
  evenBet: 'Evenbet',
};
