import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function B6({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='b-06_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style>{'.b-06_svg__st9{fill:#d4d1b4}'}</style>
        <linearGradient
          id='b-06_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#b-06_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='b-06_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={16.439}
            y1={37.751}
            x2={53.561}
            y2={37.751}
          >
            <stop offset={0} stopColor='#3a3a3f' />
            <stop offset={1} stopColor='#040408' />
          </linearGradient>
          <path
            fill='url(#b-06_svg__SVGID_2_)'
            d='M49.5 53.3v-3.4l-4.7-4V29.7l5.4-3.4V16.2h-7.4v4h-4.1v-4h-7.4v4h-4.1v-4h-7.4v10.1l5.4 3.4v16.2l-4.7 4v3.4h-4.1v6h37.2v-6z'
          />
          <linearGradient
            id='b-06_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35}
            y1={60.849}
            x2={35}
            y2={14.653}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M50.2 16.2v10.1l-5.4 3.4v16.2l4.7 4v3.4h4v6.1H16.4v-6.1h4v-3.4l4.7-4V29.7l-5.4-3.4V16.2h7.4v4h4v-4h7.4v4h4v-4h7.7m1.5-1.5H41.3v4h-1v-4H29.9v4h-1v-4H18.5v12.4l.7.4 4.7 2.9v14.7l-4.2 3.6-.7.5V51.8h-4v9.1h40.1v-9.1H51V49.2l-.5-.4-4.2-3.6V30.5l4.7-2.9.7-.4V14.7z'
            fill='url(#b-06_svg__SVGID_3_)'
          />
          <path
            className='b-06_svg__st9'
            d='M22.5 51.2h25v2h-25zM27.2 29h15.5v2H27.2zM21.8 23.6h26.3v2H21.8zM24.5 48.6h21l-2.7-2.1H27.2z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
