import React from 'react';

import { NotationSVG } from './notation-svg';
import { VNotation } from './notation-vertical';
import { HNotation } from './notation-horizontal';

export const Notation = ({ borderWidth, orientation, showNotationInBorder }) => {
  if (!showNotationInBorder) {
    return <NotationSVG orientation={orientation} />;
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          left: -borderWidth,
          top: -12,
          bottom: -12,
          right: -12,
          color: 'rgba(255, 255, 255, 0.5)',
        }}
      >
        <VNotation orientation={orientation} borderWidth={borderWidth} />
      </div>
      <div
        style={{
          position: 'absolute',
          left: -borderWidth,

          bottom: 0,
          right: -12,
          color: 'rgba(255, 255, 255, 0.5)',
        }}
      >
        <HNotation orientation={orientation} borderWidth={borderWidth} />
      </div>
    </React.Fragment>
  );
};
