import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W4({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 70 70'
      >
        <g clipPath='url(#clip0_11)'>
          <path
            fill='url(#paint0_linear_11)'
            d='M10 69.5A9.56 9.56 0 01.5 60V10A9.56 9.56 0 0110 .5h50a9.56 9.56 0 019.5 9.5v50a9.56 9.56 0 01-9.5 9.5H10z'
          />
          <path
            fill='#fff'
            d='M60 1a9 9 0 019 9v50a9 9 0 01-9 9H10a9 9 0 01-9-9V10a9 9 0 019-9h50zm0-1H10A10 10 0 000 10v50a10 10 0 0010 10h50a10 10 0 0010-10V10A10 10 0 0060 0z'
          />
          <path
            fill='url(#paint1_linear_11)'
            d='M19.77 29.54c5.396 0 9.77-4.374 9.77-9.77S25.166 10 19.77 10 10 14.374 10 19.77s4.374 9.77 9.77 9.77z'
          />
          <path
            fill='url(#paint2_linear_11)'
            stroke='#0B0A09'
            d='M19.77 26.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
          />
          <path
            fill='url(#paint3_linear_11)'
            d='M19.77 59.54c5.396 0 9.77-4.374 9.77-9.77S25.166 40 19.77 40 10 44.374 10 49.77s4.374 9.77 9.77 9.77z'
          />
          <path
            fill='url(#paint4_linear_11)'
            stroke='#0B0A09'
            d='M19.77 56.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
          />
          <g>
            <path
              fill='url(#paint5_linear_11)'
              d='M49.77 29.54c5.396 0 9.77-4.374 9.77-9.77S55.166 10 49.77 10 40 14.374 40 19.77s4.374 9.77 9.77 9.77z'
            />
            <path
              fill='url(#paint6_linear_11)'
              stroke='#0B0A09'
              d='M49.77 26.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
            />
          </g>
          <g>
            <path
              fill='url(#paint7_linear_11)'
              d='M49.77 59.54c5.396 0 9.77-4.374 9.77-9.77S55.166 40 49.77 40 40 44.374 40 49.77s4.374 9.77 9.77 9.77z'
            />
            <path
              fill='url(#paint8_linear_11)'
              stroke='#0B0A09'
              d='M49.77 56.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_11'
            x1='35'
            x2='35'
            y1='69.5'
            y2='.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#BCC5C7' />
            <stop offset='.65' stopColor='#E6E8E9' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_11'
            x1='19.776'
            x2='19.772'
            y1='29.544'
            y2='10.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_11'
            x1='19.77'
            x2='19.77'
            y1='13.21'
            y2='26.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_11'
            x1='19.776'
            x2='19.772'
            y1='59.544'
            y2='40.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_11'
            x1='19.77'
            x2='19.77'
            y1='43.21'
            y2='56.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint5_linear_11'
            x1='49.776'
            x2='49.772'
            y1='29.544'
            y2='10.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint6_linear_11'
            x1='49.77'
            x2='49.77'
            y1='13.21'
            y2='26.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint7_linear_11'
            x1='49.776'
            x2='49.772'
            y1='59.544'
            y2='40.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint8_linear_11'
            x1='49.77'
            x2='49.77'
            y1='43.21'
            y2='56.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <clipPath id='clip0_11'>
            <path fill='#fff' d='M0 0h70v70H0z' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
