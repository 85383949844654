import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W6({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='w-06_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style />
        <linearGradient
          id='w-06_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#w-06_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='w-06_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={20.326}
            y1={39.117}
            x2={49.675}
            y2={39.117}
          >
            <stop offset={0} stopColor='#f7f5d2' />
            <stop offset={1} stopColor='#d7d4b7' />
          </linearGradient>
          <path
            d='M20.3 58.8v-4c0-.7.6-1.5.9-1.8 10.2-5.7 10.3-11.4 10.3-11.6V39l-4.5.3h-.3c-.6 0-1.1-.5-1.2-1.1l-.2-1c-.1-.7.3-1.3 1-1.4h.1l3.7-2.2-.5-.5c-1.7-1.5-2.6-3.6-2.6-5.9 0-4.4 3.6-7.9 7.9-7.9 4.4 0 7.9 3.6 7.9 7.9 0 2.2-1 4.4-2.6 5.9l-.5.5 3.7 2.2h.1c.7.1 1.1.8 1 1.4l-.2 1c-.1.6-.6 1.1-1.2 1.1h-.2l-4.5-.3v2.4c0 .2.1 6 10.3 11.6.3.3.9 1.1.9 1.8v4H20.3z'
            fill='url(#w-06_svg__SVGID_2_)'
          />
          <linearGradient
            id='w-06_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35}
            y1={61.349}
            x2={35}
            y2={16.884}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M35 19.4c4.4 0 7.9 3.6 7.9 7.9 0 2.2-1 4.4-2.6 5.9l-.5.5 3.7 2.2h.1c.7.1 1.1.8 1 1.4l-.2 1c-.1.6-.6 1.1-1.2 1.1H43l-4.6-.4v2.4c0 .2.1 6 10.3 11.6.3.3.9 1.1.9 1.8v4H20.3v-4c0-.7.6-1.5.9-1.8 10.2-5.7 10.3-11.4 10.3-11.6V39l-4.5.3h-.3c-.6 0-1.1-.5-1.2-1.1l-.2-1c-.1-.7.3-1.3 1-1.4h.1l3.7-2.2-.5-.5c-1.7-1.5-2.6-3.6-2.6-5.9.1-4.3 3.6-7.8 8-7.8m0-2.5c-5.8 0-10.4 4.7-10.4 10.4 0 2.1.6 4 1.7 5.7l-.7.5c-1.8.5-3 2.3-2.6 4.2l.2 1c.3 1.8 1.9 3.1 3.7 3.1h.5l1.7-.1c-.2 1.1-1.6 5-9 9.2l-.4.1-.3.3c-.5.5-1.6 1.9-1.6 3.6v6.5h34.3v-6.5c0-1.7-1.1-3-1.6-3.6l-.2-.3-.3-.2c-7.3-4.1-8.7-8-9-9.2l1.8.1h.5c1.8 0 3.4-1.3 3.7-3.1l.2-1c.3-1.9-.8-3.7-2.6-4.2l-.7-.5c1.1-1.7 1.7-3.7 1.7-5.7-.2-5.6-4.8-10.3-10.6-10.3z'
            fill='url(#w-06_svg__SVGID_3_)'
          />
          <path
            d='M35 19.9c4.1 0 7.4 3.3 7.4 7.4 0 2.1-.9 4.1-2.4 5.5l-1 .9 1.1.7 3 1.8.2.1h.2c.4.1.7.5.6.9l-.2 1c-.1.4-.4.6-.7.6h-.1l-4-.3-1.1-.1v3c0 .3.1 6.2 10.5 12 .3.3.7 1 .7 1.5v3.5H20.9v-3.5c0-.5.5-1.1.7-1.5 10.4-5.8 10.5-11.8 10.5-12v-3l-1.1.1-4 .3h-.1c-.4 0-.7-.3-.7-.6l-.2-1c0-.2 0-.4.1-.6.1-.2.3-.3.5-.3h.2l.2-.1 3-1.8 1.1-.7-1-.9c-1.6-1.4-2.4-3.4-2.4-5.5-.1-4.1 3.2-7.4 7.3-7.4m0-1c-4.7 0-8.4 3.8-8.4 8.4 0 2.5 1.1 4.7 2.8 6.2l-3 1.8c-1 .2-1.6 1.1-1.4 2l.2 1c.1.9.9 1.5 1.7 1.5h.3l4-.3v1.9s0 5.6-10.1 11.2c0 0-1.1 1.1-1.1 2.2v4.5H50.3v-4.5c0-1.1-1.1-2.2-1.1-2.2C38.9 47 38.9 41.4 38.9 41.4v-1.9l4 .3h.3c.8 0 1.6-.6 1.7-1.5l.2-1c.2-1-.5-1.9-1.4-2l-3-1.8c1.7-1.5 2.8-3.8 2.8-6.2-.1-4.6-3.8-8.4-8.5-8.4z'
            fill='#231f20'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
