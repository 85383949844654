import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader } from 'react-spinners';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';
import { ReferralProgramService } from 'services/api/referral-program';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};
// @ts-ignore
const useStyles = makeStyles(styles);

export default function ReferralPrograms() {
  const [referralProgramsList, setReferralProgramsList] = useState();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchReferralPrograms();
  }, []);

  const fetchReferralPrograms = async () => {
    try {
      const response = await ReferralProgramService.getAll();
      setReferralProgramsList(response.data.data);
      setLoading(false);
    } catch (e) {
      errorService.sendError('Произошла ошибка' + e.message);
    }
  };

  const onRowAdd = async (newData) => {
    const newBody = {
      ...newData,
    };
    try {
      await ReferralProgramService.insertOne(newBody);
      await fetchReferralPrograms();
    } catch (e) {
      errorService.sendError('Произошла какая то ошибка' + e.message);
    }
  };

  const onRowDelete = async (rowData) => {
    try {
      await ReferralProgramService.deleteById(rowData._id);
      await fetchReferralPrograms();
    } catch (e) {
      errorService.sendError('Произошла какая то ошибка' + e.message);
    }
  };

  const onRowUpdate = async (newData, prevData) => {
    const params = {};
    for (let key in newData) {
      if (typeof newData[key] !== 'object' && newData[key] !== prevData[key]) {
        params[key] = newData[key];
      }
    }
    try {
      await ReferralProgramService.updateById(newData._id, params);
      await fetchReferralPrograms();
    } catch (e) {
      errorService.sendError('Произошла какая то ошибка' + e.message);
    }
  };

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление реферальными программами</p>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Box textAlign="center">
                  <BeatLoader size={16} color="#00acc1" loading={true} />
                </Box>
              ) : (
                <>
                  {/* @ts-ignore */}
                  <MaterialTable
                    title="Реферальные программы"
                    localization={{
                      body: {
                        editTooltip: 'Редактировать',
                        deleteTooltip: 'Удалить',
                        editRow: {
                          deleteText: 'Вы уверены что хотите удалить?',
                        },
                      },
                      header: {
                        actions: 'Действия',
                      },
                    }}
                    columns={[
                      {
                        title: 'ID',
                        field: '_id',
                        editable: false,
                      },
                      {
                        title: 'Referral ID',
                        field: 'referralId',
                      },
                      {
                        title: 'Реферер',
                        render: (rowData) => `
                        ${rowData.owner.email}
                        ${rowData.owner.nickname}
                      `,
                        editable: false,
                      },
                      {
                        title: 'Комиссия Dicechess',
                        field: 'dicechessCommission',
                        type: 'numeric',
                        validate: (rowData) => Boolean(rowData.dicechessCommission),
                      },
                      {
                        title: 'Комиссия Jackpoker',
                        field: 'jackpokerCommission',
                        type: 'numeric',
                        validate: (rowData) => Boolean(rowData.jackpokerCommission),
                      },
                      {
                        title: 'Комиссия Sports',
                        field: 'sportsCommission',
                        type: 'numeric',
                        validate: (rowData) => Boolean(rowData.sportsCommission),
                      },
                      {
                        title: 'Бонус',
                        field: 'bonusAmount',
                        type: 'numeric',
                        validate: (rowData) => Boolean(rowData.bonusAmount),
                      },
                      {
                        title: 'Owner ID',
                        field: 'ownerId',
                      },
                      {
                        title: 'URL',
                        field: 'url',
                        editable: false,
                      },
                      {
                        title: 'Количество пользователей',
                        field: 'numberOfUsers',
                        editable: false,
                        render: (rowData) => (
                          <span
                            style={{ color: '#9c27b0', cursor: 'pointer' }}
                            onClick={() =>
                              history.push(`/admin/users?referralProgramId[]=${rowData._id}`)
                            }
                          >
                            {rowData.numberOfUsers}
                          </span>
                        ),
                      },
                    ]}
                    data={referralProgramsList}
                    options={{
                      filtering: true,
                    }}
                    editable={{
                      isEditable: () => true,
                      onRowAddCancelled: () => console.log('Row adding cancelled'),
                      onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                      onRowAdd,
                      onRowUpdate,
                      onRowDelete,
                    }}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
