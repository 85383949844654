import React, { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import qs from 'qs';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import moment from 'moment';
import { useParams } from 'react-router';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';
import { useStore } from 'hooks/use-store';
import { URLHelper } from 'shared/helpers/URLHelper';
import { useDebounce } from 'hooks/use-debounce';
import { getFilters } from 'views/Transactions';
import { RatingHistoryService } from 'services/api/rating-history';
import { appColors } from 'shared/config';
import { CreateRatingHistoryForm } from 'features/createRatingHistory';
import { emitter } from 'shared/emitter';

import TableTitle from '../../components/TableTitle/TableTitle';

const getAmountColor = (value: number) => {
  return {
    color: value > 0 ? appColors.profitColor : appColors.lossColor,
  };
};

const RatingHistories = () => {
  const { externalGameProviders } = useStore();
  const { userId } = useParams<{ userId: string }>();

  const externalGameProviderLookup = useMemo(() => {
    return _.reduce(
      externalGameProviders,
      (coll, provider) => {
        coll[provider._id] = provider.name;
        return coll;
      },
      {},
    );
  }, [externalGameProviders]);

  const isPersonalHistory = userId && userId !== '0';

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Пользователь',
        accessorKey: 'user.email',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
        enableFiltering: false,
        enableEditing: false,
        enableSorting: false,
      },
      {
        header: 'Дата',
        accessorKey: 'created',
        Cell: ({ cell }) => {
          return moment(cell.getValue() as string).format('HH:mm:ss DD-MM-YYYY');
        },
        enableFiltering: false,
        enableEditing: false,
        enableSorting: false,
      },
      {
        header: 'Рейтинг',
        accessorKey: 'amount',
        Cell: ({ cell }) => {
          return (
            <div style={getAmountColor(cell.getValue() as number)}>
              {(cell.getValue() as number)?.toFixed(2)}
            </div>
          );
        },
        enableFiltering: false,
        enableEditing: false,
        enableSorting: false,
      },
      {
        header: 'Описание',
        accessorKey: 'description',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
        enableFiltering: false,
        enableEditing: false,
        enableSorting: false,
      },
      {
        header: 'Game ID',
        accessorKey: 'gameId',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
        enableFiltering: false,
        enableEditing: false,
        enableSorting: false,
      },
      {
        header: 'ID',
        accessorKey: '_id',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
        enableFiltering: false,
        enableEditing: false,
        enableSorting: false,
      },
    ],
    [externalGameProviderLookup],
  );

  const defaultFilters = useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        const searchQueryFilter = searchQueryFilters[column.accessorKey as string];
        if (searchQueryFilter) {
          acc.push({
            id: column.accessorKey as string,
            value: searchQueryFilter,
          });
        }
        return acc;
      },
      [] as { id: string; value: any }[],
    );
  }, [searchQueryFilters]);

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState<{ id: string; value: any }[]>(defaultFilters);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 30,
  });

  const debouncedColumnFilters = useDebounce(columnFilters, 700);

  const getRatingHistories = async () => {
    try {
      setIsLoading(true);

      URLHelper.replaceSearchParams(
        qs.stringify({
          ...columnFilters,
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
        }),
      );

      const filterByUser = isPersonalHistory ? { user: userId } : {};

      const filters = getFilters(columnFilters);

      const { data: remoteData } = await RatingHistoryService.findAll({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        ...filters,
        ...filterByUser,
      });
      setData(remoteData.data);
      setRowCount(remoteData.total);
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRatingHistories();
  }, [debouncedColumnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  return (
    <div>
      <CreateRatingHistoryForm />
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <TableTitle>Управление рейтингами</TableTitle>
            </CardHeader>
            <CardBody>
              <MaterialReactTable
                columns={columns}
                data={data}
                getRowId={(row: any) => row._id}
                initialState={{
                  showColumnFilters: true,
                }}
                enableMultiSort={false}
                manualFiltering={false}
                manualPagination
                manualSorting={false}
                enableGlobalFilter={false}
                enableSorting={false}
                enableFilters={false}
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                enableColumnFilterModes={false}
                localization={MRT_Localization_RU}
                enableToolbarInternalActions
                state={{
                  columnFilters,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  sorting,
                }}
                renderTopToolbar={({ table }) => {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box>
                        {isPersonalHistory && (
                          <IconButton
                            onClick={() => {
                              emitter.emit('ratingHistory:createForm:open', {
                                userId,
                                onSuccess: () => {
                                  getRatingHistories();
                                },
                              });
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                      </Box>
                    </Box>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default RatingHistories;
