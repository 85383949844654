import React, { useState, useLayoutEffect, useRef, useMemo } from 'react'

import cx from 'classnames'

import { StyleSheet, css } from 'aphrodite'

import {
  getBlackFiguresIcons,
  getWhiteFiguresIcons,
  getBlackDigitsIcons,
  getWhiteDigitsIcons
} from './textures'

import { animations } from './animations'

import { useStyles } from './styles'

function randomFromArray(array) {
  return array[Math.floor(Math.random() * array.length)]
}

function getPosition(index) {
  const offsets = [360, -360]

  const offsetAngle = randomFromArray(offsets)
  const offsetAngleY = randomFromArray(offsets)
  const offsetAngleX = randomFromArray(offsets)
  const offsetAngleZ = randomFromArray(offsets)

  const offsetAngle2D = 0 //Math.floor(Math.random() * 20 - 10);

  const positions = {
    1: {
      x: offsetAngle + offsetAngleX,
      y: 360,
      z: 360 + offsetAngleZ + offsetAngle2D
    },
    2: {
      x: -90 + 360,
      y: offsetAngle + 360 + offsetAngleY + offsetAngle2D,
      z: offsetAngle + offsetAngleZ
    },
    3: {
      x: -270 + 360 + offsetAngleX,
      y: offsetAngle + 360 + offsetAngleY + offsetAngle2D,
      z: offsetAngle + 360 + offsetAngleZ
    },
    4: {
      x: 360 + offsetAngleX,
      y: -270 + 360 + offsetAngleY,
      z: offsetAngle + 360 + offsetAngleZ
    },
    5: {
      x: offsetAngle + offsetAngleX,
      y: -90 + 360 + offsetAngleY,
      z: 360 + offsetAngleZ
    },
    6: {
      x: -180 + 360 + offsetAngleX,
      y: offsetAngle + offsetAngleY,
      z: 180 + 360 + offsetAngleZ + offsetAngle2D
    }
  }

  return positions[index]
}

function Dice3DTemplate({ textures, styles }) {
  const {
    _3dface,
    _3dface__front,
    _3dface__top,
    _3dface__front__inner,
    _3dface__top__inner,
    _3dface__bottom__inner,
    _3dface__bottom,
    _3dface__left,
    _3dface__right,
    _3dface__back,
    _3dface_left_inner,
    _3dface__right__inner,
    _3dface__back__inner,
    inner
  } = styles
  return (
    <React.Fragment>
      <div className={cx(inner, _3dface__front__inner)} />
      <div className={cx(_3dface, _3dface__front)}>{textures[0]}</div>
      <div className={cx(inner, _3dface__top__inner)} />
      <div className={cx(_3dface, _3dface__top)}>{textures[1]}</div>
      <div className={cx(inner, _3dface__bottom__inner)} />
      <div className={cx(_3dface, _3dface__bottom)}>{textures[2]}</div>
      <div className={cx(inner, _3dface_left_inner)} />
      <div className={cx(_3dface, _3dface__left)}>{textures[3]}</div>
      <div className={cx(inner, _3dface__right__inner)} />
      <div className={cx(_3dface, _3dface__right)}>{textures[4]}</div>
      <div className={cx(inner, _3dface__back__inner)} />
      <div className={cx(_3dface, _3dface__back)}>{textures[5]}</div>
    </React.Fragment>
  )
}

function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

const angles = [360, -360]

export const Dice = (props) => {
  const { player, size } = props

  const whiteDigitsIcons = useMemo(() => getWhiteDigitsIcons(size), [size])
  const blackDigitsIcons = useMemo(() => getBlackDigitsIcons(size), [size])

  const whiteFiguresIcons = useMemo(() => getWhiteFiguresIcons(size), [size])
  const blackFiguresIcons = useMemo(() => getBlackFiguresIcons(size), [size])

  const styles = useStyles({
    cubeWidth: size,
    cubeHeight: size,
    cubeDepth: size,
    innerOffset: 3
  })

  const { index, prevIndex, prefix, noanimation, figures, color } = props
  const { x: X = 0, y: Y = 0, z: Z = 0, p: P = 400 } = props

  const isWhite = useMemo(() => color === 'w', [color])

  const refAnimation = useRef()

  const [jumpStyle, setJumpStyle] = useState()
  const [rotateStyle, setRotateStyle] = useState()

  const duration = getRandom(750, 3000)

  useLayoutEffect(() => {
    if (!noanimation) {
      setJumpStyle(null)
      setRotateStyle(null)
    } else {
      const { x, y, z } = getPosition(index === 0 ? 1 : index)

      const styles = StyleSheet.create({
        init: {
          transform: `rotateX(${x}deg) rotateY(${y}deg) rotateZ(${z}deg)`
        }
      })

      setRotateStyle(css(styles.init))
    }
    //eslint-disable-next-line
  }, [index, color, prefix])

  useLayoutEffect(() => {
    if (jumpStyle === null) {
      const styles = StyleSheet.create({
        bounce: {
          //animationName: randomFromArray(animations),
          animationName: animations[2],
          animationDuration: duration + 'ms'
        }
      })

      setTimeout(() => {
        setJumpStyle(css(styles.bounce))
      }, 100)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jumpStyle])

  useLayoutEffect(() => {
    if (rotateStyle === null) {
      const { x: prevX, y: prevY, z: prevZ } = getPosition(
        prevIndex ? prevIndex : 1
      )

      const { x, y, z } = getPosition(index === 0 ? 1 : index)

      const offset = randomFromArray(angles)

      const styles = StyleSheet.create({
        rotator: {
          transform: `rotateX(${x + X + offset}deg) rotateY(${
            y + Y + offset
          }deg) rotateZ(${z + Z + offset}deg) `,
          transition: `all ${duration}ms`
        },
        init: {
          transform: `rotateX(${prevX}deg) rotateY(${prevY}deg) rotateZ(${prevZ}deg)`
        }
      })

      setRotateStyle(css(styles.init))

      setTimeout(() => {
        setRotateStyle(css(styles.rotator))
      }, 100)

      setTimeout(() => {
        if (player && !noanimation) player()
      }, duration)
    }
    //eslint-disable-next-line
  }, [rotateStyle])

  return (
    <div
      style={{
        perspective: 600,
        margin: '0 auto'
      }}
    >
      <div
        ref={refAnimation}
        className={cx(styles.space3d, jumpStyle)}
        style={{ perspective: P }}
      >
        <div className={cx(styles._3dbox, rotateStyle)}>
          {figures ? (
            <Dice3DTemplate
              styles={styles}
              textures={isWhite ? whiteFiguresIcons : blackFiguresIcons}
            />
          ) : (
            <Dice3DTemplate
              styles={styles}
              textures={isWhite ? whiteDigitsIcons : blackDigitsIcons}
            />
          )}
        </div>
      </div>
    </div>
  )
}

Dice.defaultProps = {
  size: 60,
  index: 0,
  animation: false,
  color: 'b',
  figures: false
}
