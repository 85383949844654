import React, { memo, useEffect, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Select , MenuItem } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { errorService } from 'services/alert/services';
import { useStore } from 'hooks/use-store';

import UploadImage from '../Banners/components/UploadImage';
import { FileService } from '../../services/api/file';

import { externalGameTypes, externalGameProviderTypes } from './constant';
import { dialogStyles } from './styles';


const validationSchema = Yup.object().shape({
  gameId: Yup.string().required('Введите ID'),
})


const CreateOrUpdateExternalGameModal = ({
  open,
  handleClose,
  mode,
  externalGame,
  onCreate,
  onUpdate,
}) => {
  const styles = dialogStyles();
  const { externalGameProviders } = useStore();
  const handleSubmit = useCallback(async (values, actions: FormikHelpers<Record<string, any>>) => {
    try {
      if (mode === 'create') await onCreate(values);
      else await onUpdate(externalGame._id, values);
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      actions.setSubmitting(false)
      handleClose();
    }
  }, [mode, handleClose, onCreate, onUpdate]);

  const onUpload = async (files, setFieldValue) => {
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('files', file as Blob);
      });
      const { data } = await FileService.uploadFiles(formData);
      setFieldValue('image', {
        filename: data.url,
        url: null,
      });
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        gameId: '',
        image: {
          link: '',
          filename: '',
        },
        providerType: 'algNet',
        type: 'slots',
        description: '',
        name: '',
        gameType: '',
        enabled: false,
        isNew: true,
        isPopular: false,
        order: 1,
        provider: '',
      };
    }
    return {
      gameId: externalGame.gameId,
      image: externalGame.image,
      providerType: externalGame.providerType,
      type: externalGame.type,
      description: externalGame.description,
      name: externalGame.name ?? "",
      gameType: externalGame.gameType,
      enabled: externalGame.enabled,
      order: externalGame.order,
      isNew: externalGame.isNew,
      isPopular: externalGame.isPopular,
      provider: externalGame.provider,
    };
  }, [mode, externalGame]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle>{mode === 'create' ? 'Создать' : 'Обновить'} игру</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={handleSubmit}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              values,
              errors,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.gameId && touched.gameId)}
                      name="gameId"
                      margin="dense"
                      label="ID"
                      value={values.gameId}
                      placeholder="Введите ID"
                    />
                    <div className={styles.errorText}>{touched.gameId ? errors.gameId as string : ''}</div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    {!values.image?.filename && (
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!((errors.image as Record<string, string>)?.url && (touched.image as Record<string, boolean>)?.url)}
                        name="image.url"
                        margin="dense"
                        label="Изображение"
                        value={values.image?.url}
                        placeholder="Введите ссылку на изоб."
                      />
                    )}
                    <div className={styles.errorText}>{touched.image ? errors.image as string : ''}</div>
                    {!values.image.url && (
                      <UploadImage
                        onUpload={(files) => onUpload(files, setFieldValue)}
                        image={values.image}
                        onImageRemove={() => setFieldValue('image', { filename: '', url: '' })}
                      />
                    )}
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.providerType}
                      label="Провайдер"
                      name="providerType"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.entries(externalGameProviderTypes).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.type}
                      label="Тип игры"
                      name="type"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.entries(externalGameTypes).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.name && touched.name)}
                      name="name"
                      margin="dense"
                      label="Название(обязательное поле)"
                      value={values.name}
                      placeholder="Введите название"
                    />
                    <div className={styles.errorText}>{touched.name ? errors.name as string : ''}</div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.description && touched.description)}
                      name="description"
                      margin="dense"
                      label="Описание"
                      value={values.description}
                      placeholder="Введите описание"
                    />
                    <div className={styles.errorText}>
                      {touched.description ? errors.description as string : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.gameType && touched.gameType)}
                      name="gameType"
                      margin="dense"
                      label="Тип игры"
                      value={values.gameType}
                      placeholder="Введите gameType"
                    />
                    <div className={styles.errorText}>
                      {touched.gameType ? errors.gameType as string : ''}
                    </div>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.provider}
                      label="Провайдер игры"
                      name="provider"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {externalGameProviders.map((provider) => (
                        <MenuItem key={provider._id} value={provider._id}>
                          {provider.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.order && touched.order)}
                      name="order"
                      margin="dense"
                      label="Порядок игры"
                      value={values.order}
                      placeholder="Порядок игры"
                      type="number"
                    />
                    <div className={styles.errorText}>{touched.order ? errors.order as string : ''}</div>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.checkboxFormControl)}
                    size="small"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.enabled)}
                          name="enabled"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Включено?</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.isNew)}
                          name="isNew"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Новая?</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.isPopular)}
                          name="isPopular"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Популярная?</span>}
                    />
                  </FormControl>
                  <DialogActions>
                    <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                      {mode === 'create' ? 'Создать' : 'Обновить'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(CreateOrUpdateExternalGameModal);
