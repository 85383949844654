import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import GamesTable from 'components/GamesTable';
import { UserProfileDialog } from 'components/UserProfile';
import { useAPI } from 'hooks/use-api';
import endpoints from 'api/endpoints';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

export default function Games() {
  const params = useParams();
  const [user, setUser] = useState(null);
  const [stats, setStats] = useState(null);
  const [isUserDialogOpen, setUserDialogOpen] = useState(false);
  const [{ data, isLoading }, doGetUserProfile] = useAPI();

  const { userId } = params;

  const isPersonalGames = userId && userId !== '0';

  const classes = useStyles();

  const onPlayerClick = useCallback(async (player) => {
    await doGetUserProfile(endpoints.user + '/' + player._id);
    setUserDialogOpen(true);
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>
                Игры{' '}
                {isPersonalGames && user
                  ? `пользователя ${user.nickname} (${user.email})`
                  : undefined}{' '}
                {isPersonalGames && stats && stats.games ? (
                  <b>
                    Всего игр: {stats.games.played}, побед: {stats.games.won}, поражений:{' '}
                    {stats.games.lost}
                  </b>
                ) : undefined}
              </p>
            </CardHeader>
            <CardBody>
              <UserProfileDialog
                id={data?._id}
                open={isUserDialogOpen}
                onHandleClose={() => {
                  setUserDialogOpen(false);
                }}
              />
              <GamesTable
                isPersonalGames={isPersonalGames}
                userId={userId}
                setUser={setUser}
                setStats={setStats}
                onPlayerClick={onPlayerClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
