import React from 'react'
import { Grid, Avatar } from '@material-ui/core'
import { KilledPieces } from '../killed-pieces'

export const PlayerInfo = (props) => {
  const { name, color, textColor = 'black' } = props

  const { killedPieces } = props

  return (
    <Grid container>
      <Grid item xs='auto'>
        <Avatar
          style={{
            boxSizing: 'border-box',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px green'
          }}
          alt={name.toUpperCase()}
        >
          {name[0].toUpperCase()}
        </Avatar>
      </Grid>
      <Grid item xs>
        <Grid
          container
          direction='column'
          justify='space-between'
          alignItems='flex-start'
        >
          <Grid
            item
            xs={12}
            style={{
              color: textColor,
              marginLeft: 8,
              fontSize: 15,
            }}
          >
            {name}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              border: '0px solid orange',
              position: 'relative',
              left: 8
            }}
          >
            <KilledPieces color={color} killedPieces={killedPieces} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
