export const blockReasons = {
  underageGambling: 'Несовершеннолетние',
  suspectedFraudOrIllegalActivity: 'Подозрение в мошенничестве или незаконной деятельности',
  multipleAccounts: 'Несколько аккаунтов',
  termsViolation: 'Нарушение условий пользования',
  identityVerification: 'Проверка подлинности личности',
  suspiciousBehaviorOrPatterns: 'Подозрительное поведение',
  accountInactivity: 'Неактивность аккаунта',
  abusiveBehavior: 'Оскорбительное поведение',
  useOfBots: 'Использование ботов',
  technicalIssues: 'Технические проблемы',
};
