import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    width: '100%',
    display: 'grid',
    gridColumnGap: '15px',
    position: 'relative',
    padding: '10px',
    background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    borderRadius: 10,
  },
  slider: {
    width: '100%',
    height: '150px',
  },
  sliderItem: {
    width: '100%',
  },
  imageBox: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  closeIcon: {
    position: 'absolute',
    right: 5,
    top: 5,
    cursor: 'pointer',
    color: '#fff',
  },
  deleteImageIcon: {
    position: 'absolute',
    right: 5,
    top: 5,
    cursor: 'pointer',
    color: '#fff',
  },
});
