import React, { useMemo } from 'react';

import uuid from 'react-uuid';

import cx from 'classnames';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isAndroid, isMobileSafari } from 'react-device-detect';

import Square from './square';
import SquareWithFigure from './square-with-piece';
import { SVGDefs } from './piece-memos/svg-defs';
import PieceDragLayer from './piece-drag-layer';

import { WPIcon, WRIcon, WNIcon, WBIcon, WQIcon, WKIcon } from './piece-memos';
import { BPIcon, BRIcon, BNIcon, BBIcon, BQIcon, BKIcon } from './piece-memos';
import glow from './images/glow.svg';

import { Notation } from './notation';

import { useStyles } from './styles';

export const FastChessboard = ({
  mobileHover = true,
  width = 400,
  orientation = 'white',
  fen = '8/8/8/8/8/8/8/8',
  onSquareClick = () => {},
  squareStyles = {},
  onDrop = () => {},
  beginDrag = () => {},
  allowDrag = () => true,
  border = false,
  showNotationInBorder = false,
  borderWidth = 12,
  borderRadius = 6,
  marginTop = 4,
  marginBottom = 4,
}) => {
  const classes = useStyles({ borderWidth });

  const localFen = fen;

  const pieceWidth = width > 400 ? width / 8 : width / 9;

  const defsParams = useMemo(
    () => ({
      wPid: uuid(),
      wRid: uuid(),
      wNid: uuid(),
      wBid: uuid(),
      wQid: uuid(),
      wKid: uuid(),
      bPid: uuid(),
      bRid: uuid(),
      bNid: uuid(),
      bBid: uuid(),
      bQid: uuid(),
      bKid: uuid(),
    }),
    [],
  );

  const pieces = useMemo(() => {
    return {
      wp: <WPIcon id={defsParams.wPid} width={pieceWidth} mobileHover={mobileHover} />,
      wr: <WRIcon id={defsParams.wRid} width={pieceWidth} mobileHover={mobileHover} />,
      wn: <WNIcon id={defsParams.wNid} width={pieceWidth} mobileHover={mobileHover} />,
      wb: <WBIcon id={defsParams.wBid} width={pieceWidth} mobileHover={mobileHover} />,
      wq: <WQIcon id={defsParams.wQid} width={pieceWidth} mobileHover={mobileHover} />,
      wk: <WKIcon id={defsParams.wKid} width={pieceWidth} mobileHover={mobileHover} />,
      bp: <BPIcon id={defsParams.bPid} width={pieceWidth} mobileHover={mobileHover} />,
      br: <BRIcon id={defsParams.bRid} width={pieceWidth} mobileHover={mobileHover} />,
      bn: <BNIcon id={defsParams.bNid} width={pieceWidth} mobileHover={mobileHover} />,
      bb: <BBIcon id={defsParams.bBid} width={pieceWidth} mobileHover={mobileHover} />,
      bq: <BQIcon id={defsParams.bQid} width={pieceWidth} mobileHover={mobileHover} />,
      bk: <BKIcon id={defsParams.bKid} width={pieceWidth} mobileHover={mobileHover} />,
    };
  }, [defsParams, width]);

  const dragPieces = useMemo(() => {
    return {
      wp: <WPIcon id={defsParams.wPid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      wr: <WRIcon id={defsParams.wRid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      wn: <WNIcon id={defsParams.wNid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      wb: <WBIcon id={defsParams.wBid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      wq: <WQIcon id={defsParams.wQid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      wk: <WKIcon id={defsParams.wKid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      bp: <BPIcon id={defsParams.bPid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      br: <BRIcon id={defsParams.bRid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      bn: <BNIcon id={defsParams.bNid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      bb: <BBIcon id={defsParams.bBid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      bq: <BQIcon id={defsParams.bQid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
      bk: <BKIcon id={defsParams.bKid} type="drag" width={pieceWidth} mobileHover={mobileHover} />,
    };
  }, [defsParams, width]);

  const isWhiteOrientation = useMemo(() => orientation[0] === 'w', [orientation]);

  const notationVertical = useMemo(
    () => (isWhiteOrientation ? [8, 7, 6, 5, 4, 3, 2, 1] : [1, 2, 3, 4, 5, 6, 7, 8]),
    [isWhiteOrientation],
  );

  const notationHorizontal = useMemo(
    () =>
      isWhiteOrientation
        ? ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']
        : ['h', 'g', 'f', 'e', 'd', 'c', 'b', 'a'],
    [isWhiteOrientation],
  );

  const size = useMemo(() => {
    const padding = 24;
    const maxWidth = width;

    if (window.innerWidth > maxWidth)
      return {
        width: maxWidth - padding,
        height: maxWidth - padding,
      };

    return {
      width: window.innerWidth - padding,
      height: window.innerWidth - padding,
    };
  }, [window.innerWidth, width]);

  const flatFen = useMemo(() => {
    const parts = localFen.split('');

    const flat = parts.reduce((acc, item) => {
      if (item === '/') return acc;
      if (Number(item)) {
        return [...acc, ...new Array(Number(item)).fill(null)];
      }
      return [...acc, item];
    }, []);

    return isWhiteOrientation ? flat : flat.reverse();
  }, [localFen, isWhiteOrientation]);

  const hasNative = document && (document.elementsFromPoint || document.msElementsFromPoint);

  function getDropTargetElementsAtPoint(x, y, dropTargets) {
    return dropTargets.filter((t) => {
      const rect = t.getBoundingClientRect();
      return x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top;
    });
  }

  const iOSSafariOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
  };

  const desktopOptions = {
    enableTouchEvents: false,
    enableMouseEvents: true,
  };

  const isMobile = isAndroid || isMobileSafari;

  const options = !isMobile ? desktopOptions : isMobileSafari ? iOSSafariOptions : {};

  return (
    <DndProvider options={options} backend={TouchBackend}>
      <div
        style={{
          marginTop,
          marginBottom,
        }}
      >
        <SVGDefs {...defsParams} />
        <PieceDragLayer pieces={dragPieces} />

        <div
          className={border ? cx(classes.border, classes.chessboard) : classes.chessboard}
          style={{
            lineHeight: 'normal',
            width: size.width,
            height: size.height,
            borderRadius: borderRadius,
          }}
        >
          <Notation
            borderWidth={borderWidth}
            orientation={orientation}
            showNotationInBorder={showNotationInBorder}
          />
          {flatFen.map((piece, i) => {
            const h = i % 8;
            const v = Math.trunc(i / 8);

            const square = notationHorizontal[h] + notationVertical[v];

            if (piece) {
              return (
                <SquareWithFigure
                  key={i}
                  i={i}
                  square={square}
                  pieces={pieces}
                  piece={piece}
                  onDrop={onDrop}
                  allowDrag={allowDrag}
                  beginDrag={beginDrag}
                  squareStyles={squareStyles}
                  onSquareClick={onSquareClick}
                  mobileHover={mobileHover}
                />
              );
            } else {
              return (
                <Square
                  key={i}
                  i={i}
                  square={square}
                  onDrop={onDrop}
                  allowDrag={allowDrag}
                  squareStyles={squareStyles}
                  onSquareClick={onSquareClick}
                  mobileHover={mobileHover}
                />
              );
            }
          })}
        </div>
      </div>
    </DndProvider>
  );
};
