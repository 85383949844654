import React, { useMemo, useRef, memo } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthAPI } from 'hooks/use-auth-api';
import qs from 'qs';

import { STATUS_MESSAGES, CAUSE_MESSAGES, BALANCE_TYPE_MESSAGES } from 'types/enums';

import MaterialTable from 'material-table';

import Player from 'components/Player';

import endpoints from 'api/endpoints';
import { errorService } from 'services/alert/services';
import { mapFilters } from 'utils/map-table-filters';
import { getPopulatedPlayer } from 'utils/game';
import { URLHelper } from 'shared/helpers/URLHelper';

const transformFilterQueries = {};
const MIN_PAGE_SIZE = 30;
const pageSizes = [30, 50, 100];

const GamesTable = memo(({ isPersonalGames, userId, setUser, setStats, onPlayerClick }) => {
  const request = useAuthAPI();
  const history = useHistory();
  const location = useLocation();
  // TODO: remove material-table and find a replacement for it and remove this useRef and refactor it
  const isFirstRender = useRef(true);

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const handleData = async (query) => {
    try {
      const filters = mapFilters(query.filters, transformFilterQueries);

      const orderQuery =
        query.orderBy && query.orderBy.field
          ? { orderBy: query.orderBy.field, orderDirection: query.orderDirection }
          : {};
      const filterByUserId = isPersonalGames ? { userId } : {};

      const page = isFirstRender.current
        ? !isNaN(+searchQueryFilters.page)
          ? +searchQueryFilters.page
          : query.page
        : Number(query.page);

      isFirstRender.current = false;

      URLHelper.replaceSearchParams(qs.stringify({ ...filters, page, pageSize: query.pageSize }));

      const response = await request(endpoints.games, {
        limit: query.pageSize,
        offset: page * query.pageSize,
        ...orderQuery,
        ...filters,
        ...filterByUserId,
      });
      setUser(response.data.user);
      setStats(response.data.stats);
      return {
        data: response.data.data,
        page: page,
        totalCount: response.data.total,
        pageSize: query.pageSize,
      };
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Дата',
        field: 'created',
        type: 'date',
        cellStyle: {
          minWidth: 160,
        },
        defaultFilter: searchQueryFilters.created,
        render: ({ created }) => moment(created).format('HH:mm:ss DD-MM-YYYY'),
      },

      {
        title: 'Игрок 1',
        sorting: false,
        render: ({ populate_players, players, result }) => {
          return (
            populate_players[0] && (
              <Player
                player={{
                  color: players[0].color,
                  nickname: getPopulatedPlayer(players[0], populate_players).nickname,
                }}
                winnerColor={result?.winner}
                onClick={() => onPlayerClick(populate_players[0])}
              />
            )
          );
        },
      },

      {
        title: 'Игрок 2',
        sorting: false,
        render: ({ populate_players, players, result }) => {
          return (
            populate_players[1] && (
              <Player
                player={{
                  color: players[1].color,
                  nickname: getPopulatedPlayer(players[1], populate_players).nickname,
                }}
                winnerColor={result?.winner}
                onClick={() => onPlayerClick(populate_players[1])}
              />
            )
          );
        },
      },

      {
        title: 'Ставка',
        field: 'settings.bet',
        defaultFilter: searchQueryFilters['settings.bet'],
      },

      {
        title: 'Банк',
        field: 'bank',
        defaultFilter: searchQueryFilters.bank,
      },

      {
        title: 'Статус',
        field: 'status',
        sorting: false,
        defaultFilter: searchQueryFilters.status,
        lookup: STATUS_MESSAGES,
        render: (rowData) => STATUS_MESSAGES[rowData.status],
      },
      {
        title: 'Тип игры',
        field: 'settings.balanceType',
        filtering: true,
        defaultFilter: searchQueryFilters['settings.balanceType'],
        lookup: BALANCE_TYPE_MESSAGES,
        render: (rowData) => BALANCE_TYPE_MESSAGES[rowData.settings?.balanceType],
        cellStyle: {
          minWidth: 130,
        },
      },
      {
        title: 'Причина',
        field: 'result.cause',
        sorting: false,
        defaultFilter: searchQueryFilters['result.cause'],
        lookup: CAUSE_MESSAGES,
        render: (rowData) =>
          rowData.result ? CAUSE_MESSAGES[rowData.result.cause] : 'Пока не известно',
      },
      {
        title: 'Кол-во ходов',
        field: 'history.length',
        sorting: false,
        defaultFilter: searchQueryFilters['history.length'],
        render: (rowData) => rowData.history.length,
        cellStyle: {
          minWidth: 80,
        },
      },
      { title: 'id', field: '_id', defaultFilter: searchQueryFilters.id },
    ];
  }, []);

  return (
    <MaterialTable
      title="Игры"
      onRowClick={(e, rowData) => {
        history.push({ pathname: `/admin/game/${rowData._id}` });
      }}
      columns={columns}
      data={handleData}
      options={{
        filtering: true,
        pageSize: !isNaN(+searchQueryFilters.pageSize)
          ? +searchQueryFilters.pageSize
          : MIN_PAGE_SIZE,
        pageSizeOptions: pageSizes,
        debounceInterval: 700,
        search: false,
      }}
    />
  );
});

export default GamesTable;
