import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useAuthAPI } from 'hooks/use-auth-api';
import moment from 'moment';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';
import endpoints from 'api/endpoints';
import { PushNotificationService } from '../../services/api/push-notification';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const pageSize = 15;
const pageSizeOptions = [15, 20, 30, 40];

export const getTimeAndIncrement = (time) => {
  switch (time) {
    case 3:
      return { time: 3, increment: 3 };
    case 5:
      return { time: 5, increment: 5 };
    case 10:
      return { time: 10, increment: 0 };
    default:
      return { time: 5, increment: 5 };
  }
};

const useStyles = makeStyles(styles);

const PushNotifications = () => {
  const classes = useStyles();
  const request = useAuthAPI();

  const tableRef = useRef();

  const onData = async (query) => {
    try {
      const response = await request(`${endpoints.pushNotifications}`, {
        limit: query.pageSize,
        offset: query.page * query.pageSize,
      });
      return {
        data: response.data.data,
        page: query.page,
        totalCount: response.data.total,
        pageSize: query.pageSize,
      };
    } catch (e) {
      errorService.sendError('Some error occurred', e);
    }
  };

  const onRowAdd = async (rowData) => {
    const data = {
      content: rowData.contents.en,
      name: rowData.name,
    };
    try {
      await PushNotificationService.createNotification(data);
    } catch (e) {
      errorService.sendError(e?.response?.data?.message ?? e.message);
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление пуш уведомлениями</p>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title="Пуш уведомления"
                tableRef={tableRef}
                localization={{
                  body: {
                    editTooltip: 'Редактировать',
                  },
                  toolbar: { searchPlaceholder: 'Поиск' },
                  pagination: { labelRowsSelect: 'cтрок' },
                  header: {
                    actions: 'Действия',
                  },
                }}
                actions={[
                  {
                    icon: 'free',
                  },
                ]}
                columns={[
                  {
                    title: 'ID',
                    field: 'id',
                    editable: false,
                  },
                  {
                    title: 'Название',
                    field: 'name',
                  },
                  {
                    title: 'Описание',
                    field: 'contents.en',
                  },
                  {
                    title: 'Дата',
                    field: 'completed_at',
                    render: (rowData) =>
                      rowData.completed_at
                        ? moment.unix(rowData.completed_at).format('HH:mm:ss DD-MM-YYYY')
                        : '...',
                    editable: false,
                  },
                  {
                    title: 'Успешно отпр',
                    field: 'successful',
                    editable: false,
                  },
                ]}
                options={{
                  filtering: false,
                  debounceInterval: 700,
                  search: false,
                  pageSize,
                  pageSizeOptions,
                }}
                editable={{
                  isEditable: () => true,
                  onRowAdd,
                }}
                data={onData}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default PushNotifications;
