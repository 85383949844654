import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { BeatLoader } from 'react-spinners';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { PaymentService } from 'services/api/payment';
import { errorService } from 'services/alert/services';

import { balanceActions } from './constant';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  form: {
    width: 500,
    margin: '0 0',
  },
  cellItem: {
    width: '100%',
    margin: '0 0 15px',
  },
  loaderContainer: {
    width: 450,
  },
  padding: { padding: 4 },
  bold: { fontWeight: 'bolder' },
  cellError: {
    color: 'red',
  },
});

export const RejectPayoutForm = ({ paymentNo, ...props }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (data) => {
    props.onHandleClose(data);
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Причина отказа выплаты</DialogTitle>
        <DialogContent>
          <Grid container className={classes.fullWidth}>
            <Grid item className={classes.fullWidth}>
              <Grid container className={classes.fullWidth}>
                <Formik
                  initialValues={{ reason: '', balanceAction: '5' }}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    reason: Yup.string().required('Введите причину отказа'),
                  })}
                  onSubmit={async ({ reason, balanceAction }, { setSubmitting }) => {
                    try {
                      await PaymentService.rejectPayment({
                        _id: paymentNo,
                        reason,
                        balanceAction,
                      });
                      props.onSuccess();
                      handleClose();
                    } catch (e) {
                      console.log({ e });
                      errorService.sendError('Произошла ошибка', e);
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                  render={({ values, errors, handleSubmit, handleChange, isSubmitting }) => {
                    return (
                      <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid container direction="column" justify="center" alignItems="center">
                          <Grid item xs={12} className={classes.cellItem}>
                            <TextField
                              fullWidth
                              multiline
                              onChange={handleChange}
                              error={!!errors.reason}
                              name="reason"
                              value={values.reason}
                              label="Причина"
                            />
                            <span className={classes.cellError}>{errors.reason}</span>
                          </Grid>

                          <Grid item xs={12} className={classes.cellItem}>
                            <Select
                              fullWidth
                              label="Баланс"
                              onChange={handleChange}
                              error={!!errors.balanceAction}
                              name="balanceAction"
                              value={values.balanceAction}
                            >
                              {Object.entries(balanceActions).map(([value, label]) => {
                                return (
                                  <MenuItem key={value} value={value}>
                                    {label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: 20, width: 200 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={handleSubmit}
                            >
                              {isSubmitting ? (
                                <BeatLoader size={10} color="white" loading={true} />
                              ) : (
                                'Отправить'
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleClose(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

RejectPayoutForm.propTypes = {};
