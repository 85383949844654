import React from 'react';
import { useStyles } from './styles';
import { availableEmojis } from '../../../../constants/emojis';

const EmojiPicker = ({ onPick }) => {
  const classes = useStyles();
  return (
    <div className={classes.scroller}>
      <div className={classes.container}>
        {availableEmojis.map((emoji, i) => (
          <span key={i} className={classes.emoji} onClick={() => onPick(emoji)}>
            {emoji}
          </span>
        ))}
      </div>
    </div>
  );
};

export default EmojiPicker;
