import React, { useMemo, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cx from 'classnames';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useStyles } from './styles';

export default function SquareWithFigure({
  i,
  onSquareClick,
  mobileHover,
  square,
  piece,
  pieces,
  allowDrag,
  squareStyles,
  beginDrag,
  onDrop,
}) {
  const classes = useStyles();
  const type = useMemo(
    () => (piece.toUpperCase() === piece ? `w${piece}` : `b${piece}`).toLowerCase(),
    [piece],
  );

  const [{ isOver }, drop] = useDrop({
    accept: ['wp', 'wr', 'wn', 'wb', 'wq', 'wk', 'bp', 'br', 'bn', 'bb', 'bq', 'bk'],

    drop: (props) => {
      onDrop({ from: props.from, to: square });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    canDrag: allowDrag({ piece, square }),
    item: {
      type,
      from: square,
    },
    begin: (monitor) => beginDrag({ piece, square }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return (
    <div
      key={i}
      ref={drop}
      className={cx(
        classes.noselect,
        classes.square,
        isOver ? (mobileHover ? classes.hoverMobile : classes.hoverDesktop) : undefined,
      )}
      style={{
        transform: `translate(${(i % 8) * 100}%,${Math.trunc(i / 8) * 100}%)`,
        ...squareStyles[square],
      }}
      onClick={() => {
        onSquareClick(square);
      }}
    >
      <div
        ref={drag}
        style={{
          display: isDragging ? 'none' : 'block',
        }}
      >
        {pieces[type]}
      </div>
    </div>
  );
}
