import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W3({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='w-03_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style>{'.w-03_svg__st1{fill:#231f20}'}</style>
        <linearGradient
          id='w-03_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#w-03_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='w-03_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={12.194}
            y1={35.904}
            x2={57.806}
            y2={35.904}
          >
            <stop offset={0} stopColor='#f7f5d2' />
            <stop offset={1} stopColor='#d7d4b7' />
          </linearGradient>
          <path
            d='M55.6 58.3c-1-.6-3-1.7-5.1-1.7-1.2 0-2.1.3-3 .5-1.3.4-2.7.8-5.8.8-4.8 0-5.4-4-5.4-4.1l-.1-.6h-2.5l-.1.6c0 .2-.7 4.1-5.4 4.1-3.1 0-4.5-.4-5.8-.8-.9-.3-1.8-.5-3-.5-2.1 0-4.1 1.1-5.1 1.7l-2.2-4.9c.7-.6 2.4-1.7 5.3-1.7 1.4 0 2.9.3 4.5.9 1.8.6 3.4 1 4.7 1 4 0 4.9-2.6 4.9-2.7l.3-.8-.8-.2c-1.6-.2-4.2-.8-5.3-1.6l3.6-7.9h-1s-2.5-.1-4.1-1.9c-1.2-1.4-1.6-3.4-1.3-6.1.4-3.3 3.4-5.8 6-8 2-1.7 3.8-3.2 4.1-4.8l.1-.5-.3-.4c-.7-.6-1.1-1.4-1.1-2.3 0-1.7 1.5-3 3.4-3s3.4 1.4 3.4 3c0 .9-.4 1.7-1.1 2.3l-.5.3.1.6c.4 1.8 2.2 3.3 4.3 5 2.6 2.1 5.5 4.4 5.8 7.7.3 2.7-.2 4.8-1.5 6.2-1.6 1.8-3.9 1.8-3.9 1.8h-1l3.6 7.9c-1.2.7-3.8 1.3-5.3 1.6l-.8.2.3.8c0 .1 1 2.7 4.9 2.7 1.4 0 3-.3 4.7-1 1.6-.6 3.1-.9 4.5-.9 2.9 0 4.6 1.2 5.3 1.7l-2.3 5z'
            fill='url(#w-03_svg__SVGID_2_)'
          />
          <linearGradient
            id='w-03_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35}
            y1={61.252}
            x2={35}
            y2={11.465}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M35 13.5c1.9 0 3.4 1.4 3.4 3 0 .9-.4 1.7-1.1 2.3l-.4.2.1.6c.4 1.8 2.2 3.3 4.3 5 2.6 2.1 5.5 4.4 5.8 7.7.3 2.7-.2 4.8-1.5 6.2-1.6 1.8-3.9 1.8-3.9 1.8h-1l3.6 7.9c-1.2.7-3.8 1.3-5.3 1.6l-.8.2.3.8c0 .1 1 2.7 4.9 2.7 1.4 0 3-.3 4.7-1 1.6-.6 3.1-.9 4.5-.9 2.9 0 4.6 1.2 5.3 1.7l-2.2 4.9c-1-.6-3-1.7-5.1-1.7-1.2 0-2.1.3-3 .5-1.3.4-2.7.8-5.8.8-4.8 0-5.4-4-5.4-4.1l-.1-.6h-2.5l-.1.6c0 .2-.7 4.1-5.4 4.1-3.1 0-4.5-.4-5.8-.8-.9-.3-1.8-.5-3-.5-2.1 0-4.1 1.1-5.1 1.7l-2.2-4.9c.7-.6 2.4-1.7 5.3-1.7 1.4 0 2.9.3 4.5.9 1.8.6 3.4 1 4.7 1 4 0 4.9-2.6 4.9-2.7l.3-.8-.9-.1c-1.6-.2-4.2-.8-5.3-1.6l3.6-7.9h-1s-2.5-.1-4.1-1.9c-1.2-1.4-1.6-3.4-1.3-6.1.4-3.3 3.4-5.8 6-8 2-1.7 3.8-3.2 4.1-4.8l.1-.5-.3-.4c-.7-.6-1.1-1.4-1.1-2.3-.1-1.6 1.4-2.9 3.3-2.9m0-2c-3 0-5.4 2.3-5.4 5 0 1.2.4 2.3 1.2 3.2-.5.9-1.9 2.1-3.3 3.3-2.8 2.3-6.2 5.2-6.7 9.2-.4 3.3.2 5.8 1.8 7.7 1.1 1.3 2.6 2 3.7 2.3l-2.4 5.4-.7 1.6 1.5.9c.9.6 2.2 1 3.4 1.3-.4.1-.8.2-1.4.2-1.1 0-2.5-.3-4-.8-1.8-.6-3.5-1-5.2-1-3.4 0-5.6 1.4-6.6 2.2l-1.1 1 .6 1.4 2.2 4.9.9 2.1 1.9-1.2c.7-.4 2.4-1.4 4.1-1.4.9 0 1.6.2 2.4.4 1.3.4 3 .9 6.4.9 3.7 0 5.8-2 6.7-3.9.9 1.9 3 3.9 6.8 3.9 3.4 0 5-.5 6.4-.9.8-.3 1.5-.4 2.4-.4 1.6 0 3.4 1 4.1 1.4l1.9 1.2.9-2.1 2.2-4.9.6-1.4-1.2-1c-1-.8-3.1-2.2-6.6-2.2-1.6 0-3.4.3-5.2 1-1.5.6-2.9.8-4 .8-.6 0-1-.1-1.4-.2 1.2-.3 2.5-.7 3.4-1.3l1.5-.9-.7-1.6-2.4-5.4c1.1-.3 2.4-.9 3.5-2.2 1.6-1.8 2.3-4.4 2-7.7-.4-4.1-3.8-6.9-6.5-9.1-1.5-1.2-3-2.4-3.5-3.4.8-.9 1.2-2 1.2-3.2 0-2.9-2.4-5.1-5.4-5.1z'
            fill='url(#w-03_svg__SVGID_3_)'
          />
          <path
            className='w-03_svg__st1'
            d='M38.4 31H36v-2.4c0-.5-.4-1-1-1-.5 0-1 .4-1 1V31h-2.4c-.5 0-1 .4-1 1v.1c0 .5.4 1 1 1H34v2.4c0 .5.4 1 1 1 .5 0 1-.4 1-1V33h2.4c.5 0 1-.4 1-1s-.5-1-1-1z'
          />
          <path
            className='w-03_svg__st1'
            d='M52.5 51c-1.3 0-2.9.2-4.7.9-1.9.7-3.3.9-4.5.9-3.5 0-4.3-2.3-4.3-2.3s4.7-.7 6.1-2l-3.4-7.4s6.9 0 6.1-8.8c-.6-6.2-9.4-9.1-10.1-12.8 0-.1 0-.2-.1-.2.9-.7 1.4-1.7 1.4-2.8 0-2-1.8-3.7-4-3.7s-4 1.7-4 3.7c0 1.1.5 2.1 1.4 2.8 0 .1 0 .1-.1.2-.7 3.4-9.4 6.7-10.1 12.8-1.1 8.6 5.9 8.8 6.1 8.8l-3.4 7.4c1.3 1.3 6.1 2 6.1 2s-.8 2.3-4.3 2.3c-1.2 0-2.6-.3-4.5-.9-1.8-.7-3.4-.9-4.7-.9-4.1 0-6.1 2.2-6.1 2.2l2.7 6.1s2.7-2 5.4-2 3.4 1.3 8.8 1.3 6.6-5.4 6.6-5.4 1.5 5.4 6.9 5.4 6.1-1.3 8.8-1.3 5.4 2 5.4 2l2.7-6.1c-.1.1-2.1-2.2-6.2-2.2zm-20.6-1.6l-.8-.1c-1.6-.2-3.5-.7-4.6-1.2 1.2-.5 8.4-2.8 16.8 0-.1 0-.2.1-.3.1-4.1 1.4-8.1 1.5-11.1 1.2zm10.9-2.7c-2.9-.8-5.6-1.1-7.9-1.1-3.4 0-6.1.6-7.7 1.1l2.3-5 .3-.6c2-.7 5.9-1.6 10.5.1l.2.5 2.3 5zM32.3 16.5c0-1.3 1.2-2.4 2.7-2.4s2.7 1.1 2.7 2.4c0 .7-.3 1.3-.9 1.7l-.7.5c-.9.2-1.8.1-2.3 0l-.6-.5c-.6-.4-.9-1-.9-1.7zm-8.8 16c.4-3 3.2-5.4 5.8-7.5 2.1-1.8 3.9-3.3 4.3-5.1.3.1.9.1 1.5.1.4 0 .8-.1 1.3-.1.4 2 2.4 3.7 4.5 5.4 2.6 2.1 5.3 4.3 5.6 7.3.2 2.5-.2 4.4-1.3 5.7-1.4 1.5-3.4 1.6-3.4 1.6h-2.1c-1.7-.5-3.4-.7-4.8-.7-2 0-3.7.4-4.9.8h-1.7s-2.2-.1-3.6-1.7c-1.1-1.4-1.5-3.3-1.2-5.8zm4.8 24.8c-3 0-4.4-.4-5.6-.8-1-.3-1.9-.6-3.2-.6-1.8 0-3.6.8-4.8 1.4L13 53.7c.8-.5 2.2-1.3 4.4-1.3 1.3 0 2.8.3 4.2.8 1.8.7 3.5 1 5 1 4.4 0 5.5-3.1 5.5-3.2l.1-.4c.6.1 1.2.1 1.8.1l-1 3c-.1.4-.7 3.6-4.7 3.6zm27 .1c-1.2-.6-3-1.4-4.8-1.4-1.3 0-2.2.3-3.2.6-1.2.4-2.6.8-5.6.8-4.1 0-4.7-3.2-4.7-3.6l-1.2-3.1c.6 0 1.2-.1 1.8-.1l.2.5c0 .1 1.1 3.2 5.5 3.2 1.5 0 3.1-.3 5-1 1.5-.5 2.9-.8 4.2-.8 2.2 0 3.7.7 4.4 1.3l-1.6 3.6z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
