import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { FileService } from 'services/api/file';
import { errorService } from 'services/alert/services';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    margin: '5px 0',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: '10px',
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  uploadText: {
    fontSize: 16,
    color: '#000',
    fontWeight: '400',
    cursor: 'pointer',
  },
  inputFile: {
    display: 'none',
  },
  closeIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  filenameText: {
    fontSize: 16,
    color: '#000',
    fontWeight: '400',
    justifySelf: 'center',
  },
});

const UploadImageInput = ({ onChange, image }) => {
  const styles = useStyles();
  const imageRef = useRef();
  const [isLoading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const files = e.target.files;

    setLoading(true);
    await onUpload(files);
    setLoading(false);
  };

  const onUploadClick = () => {
    if (image) {
      onChange(null);
    } else {
      imageRef.current.click();
    }
  };

  const onUpload = async (files) => {
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('files', file);
      });
      const { data } = await FileService.uploadFiles(formData);
      onChange(data.url);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topWrapper}>
        <input type="file" ref={imageRef} onChange={handleChange} className={styles.inputFile} />
        <Button
          component="label"
          variant="contained"
          startIcon={
            isLoading ? <HourglassBottomIcon /> : image ? <CloseIcon /> : <CloudUploadIcon />
          }
          onClick={onUploadClick}
        >
          {isLoading ? 'Uploading' : image ? image : 'Upload file'}
        </Button>
      </div>
    </div>
  );
};

export default UploadImageInput;
