import { bannerTypesLabels, redirectTypesLabels, styleTypesLabels } from './constants';

export const mapBannerType = (type) => {
  return bannerTypesLabels[type] || type;
};

export const mapRedirectType = (type) => {
  return redirectTypesLabels[type] || type;
};

export const mapStyleType = (type) => {
  return styleTypesLabels[type] || type;
};
