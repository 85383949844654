import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/use-auth';

export const useAPI = <T = any>({ type = 'get' } = {}) => {
  const history = useHistory();
  const auth = useAuth();

  const [result, setResult] = useState<T>();
  const [data, setData] = useState();
  const [url, setUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        if (type === 'get') {
          const result = await axios.get(url!, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params: data,
          });

          setResult(result.data);
        }

        if (type === 'post') {
          const result = await axios.post(url!, data, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });

          setResult(result.data);
        }

        if (type === 'delete') {
          const result = await axios.delete(url!, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });

          setResult(result.data);
        }
      } catch (error) {
        if (_.get(error, 'response.data.statusCode') === 401) {
          setIsLoading(false);
          history.push('/login');
        }

        setIsError(true);
      }

      setIsLoading(false);
    };

    if (url) fetchData();
  }, [url]);

  return [
    { data: result, isLoading, isError },
    (url: string, data?: any) => {
      setUrl(url);
      setData(data);
    },
  ] as [{ data: any[]; isLoading: boolean; isError: boolean }, (url: string, data?: any) => void];
};
