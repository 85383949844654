import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class PaymentService {
  static async getPayments(params) {
    const data = await api.get(endpoints.payments, { params });
    return data;
  }
  static async rejectPayment(data) {
    const response = await api.post(endpoints.rejectPayment, data);
    return response;
  }
  static async approvePayment(_id) {
    const response = await api.post(endpoints.approvePayment, { _id });
    return response;
  }
  static async recreatePayout(id) {
    const response = await api.post(endpoints.recreatePayout, { id });
    return response;
  }
}
