export const STATUS_MESSAGES = {
  awaiting: 'В ожидании',
  not_ready: 'Не готова',
  in_progress: 'Идет игра',
  finished: 'Завершена',
};

export const CAUSE_MESSAGES = {
  timeout: 'Таймаут',
  capture: 'Мат',
  resign: 'Сдался',
  inactivity: 'Ушел из игры',
};

export const BALANCE_TYPE_MESSAGES = {
  coins: 'Free',
  play: 'Cash',
  referralBonus: 'Bonus',
};
