import { makeStyles } from '@material-ui/core/styles';

const HEIGHT = 40;

export const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: '12px',
    borderTop: '1px solid #eaeaf1',
    padding: '40px 0',
    background: 'rgba(255,255,255,.05)',
    position: 'relative',
  },
  icons: {
    height: HEIGHT,
  },
  iconButton: {
    height: '100%',
    width: HEIGHT,
    '@media screen and (max-width: 500px)': {
      width: 30,
    },
  },
  textArea: {
    height: HEIGHT,
    maxWidth: '100%',
    width: '100%',
    marginBottom: '10px',
    borderRadius: 5,
    border: '1px solid #cfd4d8',
    backgroundColor: '#fff',
    resize: 'none',
    padding: '5px 12px',
    boxSizing: 'border-box',
    '&::placeholder': {
      color: '#cfd4d8',
      fontSize: 14,
    },
    '& .MuiInput-underline:after': {
      content: '""',
      border: 'unset',
      borderBottom: 'unset!important',
    },
    '& .MuiInput-underline:before': {
      content: '""',
      border: 'unset',
      borderBottom: 'unset!important',
    },
  },
  button: {
    height: HEIGHT,
  },

  emojiPickerWrapper: {
    display: 'none',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 100,
    left: 5,
    borderRadius: 10,
    '@media screen and (max-width: 650px)': {
      maxWidth: '100%',
      width: '100%',
    },
    '@media screen and (max-width: 440px)': {
      left: 0,
    },
  },
  imagesPreviewWrapper: {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 100,
    padding: '0 5px',
  },
  uploadImageInput: {
    display: 'none',
  },
});
