import React from 'react';

function toWordColor(color) {
  return color === 'w' ? 'white' : 'black';
}

const WINNER_COLOR = '#21c750';

const winnerStyles = {
  color: WINNER_COLOR,
  fontWeight: '600',
};

export default function Player({ player, winnerColor, onClick }) {
  if (!player) return [];

  const handleClick = (e) => {
    e.stopPropagation();
    onClick && onClick();
  };
  return (
    <div style={{ display: 'flex', alignItems: 'left', flexWrap: 'wrap', minWidth: 190 }}>
      <div
        style={{
          width: 11,
          height: 11,
          borderRadius: '50%',
          border: '1px solid black',
          backgroundColor: toWordColor(player.color),
          margin: 4,
        }}
      />
      <div>
        <a style={winnerColor === player.color ? winnerStyles : {}} onClick={handleClick}>
          {player.nickname}
        </a>
      </div>
    </div>
  );
}
