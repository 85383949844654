import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  figure: { width: 120, height: 120 },

  space3d: (props) => ({
    width: props.cubeWidth,
    height: props.cubeHeight,
    textAlign: 'center',
    margin: '0 auto',
    display: 'flex'
  }),

  _3dbox: () => ({
    display: 'inline-block',
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
    transformStyle: 'preserve-3d'
  }),

  _3dface: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    lineHeight: props.cubeWidth,
    borderRadius: 16
  }),
  _3dface__front: (props) => ({
    width: props.cubeWidth,
    height: props.cubeHeight,
    transform: `translate3d(0, 0, ${props.cubeDepth / 2}px)`
  }),

  _3dface__bottom: (props) => ({
    width: props.cubeWidth,
    height: props.cubeHeight,
    transform: `rotateX(-90deg) translate3d(0, 0, ${props.cubeHeight / 2}px)`
  }),

  inner: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: 6,
    boxShadow: 'inset 0 0 140px #d8d9dd',
    backgroundColor: '#d8d9dd',
    willСhange: 'transform',
    backfaceVisibility: 'hidden'
  }),

  _3dface__front__inner: (props) => ({
    width: props.cubeWidth,
    height: props.cubeHeight,
    transform: `translate3d(0, 0, ${props.cubeDepth / 2 - props.innerOffset}px)`
  }),
  _3dface__top: (props) => ({
    width: props.cubeWidth,
    height: props.cubeHeight,
    transform: `rotateX(90deg) translate3d(0, 0, ${props.cubeHeight / 2}px)`
  }),

  _3dface__top__inner: (props) => ({
    width: props.cubeWidth,
    height: props.cubeHeight,
    transform: `rotateX(90deg) translate3d(0, 0, ${
      props.cubeHeight / 2 - props.innerOffset
    }px)`
  }),
  _3dface__bottom__inner: (props) => ({
    width: props.cubeWidth,
    height: props.cubeDepth,
    transform: `rotateX(-90deg) translate3d(0, 0, ${
      props.cubeHeight / 2 - props.innerOffset
    }px)`
  }),

  _3dface__left: (props) => ({
    width: props.cubeDepth,
    height: props.cubeHeight,
    left: '50%',
    marginLeft: -props.cubeDepth / 2,
    transform: `rotateY(-90deg) translate3d(0, 0, ${props.cubeWidth / 2}px)`
  }),
  _3dface__right: (props) => ({
    width: props.cubeDepth,
    height: props.cubeHeight,
    left: '50%',
    marginLeft: -props.cubeDepth / 2,
    transform: `rotateY(90deg) translate3d(0, 0, ${props.cubeWidth / 2}px)`
  }),

  _3dface__back: (props) => ({
    width: props.cubeDepth,
    height: props.cubeHeight,
    transform: `rotateY(180deg) translate3d(0, 0, ${props.cubeDepth / 2}px)`
  }),

  _3dface_left_inner: (props) => ({
    width: props.cubeDepth,
    height: props.cubeHeight,
    left: '50%',
    marginLeft: -props.cubeDepth / 2,
    transform: `rotateY(-90deg) translate3d(0, 0, ${
      props.cubeWidth / 2 - props.innerOffset
    }px)`
  }),

  _3dface__right__inner: (props) => ({
    width: props.cubeDepth,
    height: props.cubeHeight,
    left: '50%',
    marginLeft: -props.cubeDepth / 2,
    transform: `rotateY(90deg) translate3d(0, 0, ${
      props.cubeWidth / 2 - props.innerOffset
    }px)`
  }),

  _3dface__back__inner: (props) => ({
    width: props.cubeDepth,
    height: props.cubeHeight,
    transform: `rotateY(180deg) translate3d(0, 0, ${
      props.cubeDepth / 2 - props.innerOffset
    }px)`
  })
})
