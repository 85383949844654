import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader } from 'react-spinners';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import { useAPI } from 'hooks/use-api';
import { usePostAPI } from 'hooks/use-post-api';
import { usePutAPI } from 'hooks/use-put-api';

import { errorService, infoService, warnService } from 'services/alert/services';

import endpoints from 'api/endpoints';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

const thresholdSchema = Yup.object().shape({
  threshold: Yup.number()
    .required('Введите порог'),
});

const rakeSchema = Yup.object().shape({
  rake: Yup.number()
    .required('Введите рейк'),
});

function validateField(schema, data) {
  try {
    schema.validateSync(data);
  } catch (error) {
    return error.message;
  }
  return true;
}

export default function RakeLevels() {
  const [rakeLevelList, setRakeLevelList] = useState();
  
  const [{ data, isLoading, isError }, doGetRakeLevels] = useAPI();
  const [
    { data: createRakeLevelData, isLoading: isCreateRakeLevelLoading, isError: isCreateRakeLevelError },
    doCreateRakeLevel,
  ] = usePostAPI({});
  const [
    { data: updateRakeLevelData, isLoading: isUpdateRakeLevelLoading, isError: isUpdateRakeLevelError },
    doUpdateRakeLevel,
  ] = usePutAPI({});

  useEffect(() => {
    if (data) setRakeLevelList(data);
  }, [data]);

  useEffect(() => {
    doGetRakeLevels(endpoints.rakeLevels);
  }, []);

  useEffect(() => {
    if (isError || isCreateRakeLevelError || isUpdateRakeLevelError)
      errorService.sendError('Unknown Error API!');
  }, [isError, isCreateRakeLevelError, isUpdateRakeLevelError]);

  useEffect(() => {
    if (isCreateRakeLevelError)
      errorService.sendError(
        'Невозможно создать уровень рейка: ' + createRakeLevelData.response.message,
      );
  }, [isCreateRakeLevelError]);

  useEffect(() => {
    if (isUpdateRakeLevelError)
      errorService.sendError(
        'Невозможно изменить уровень рейка: ' + updateRakeLevelData.response.message,
      );
  }, [isUpdateRakeLevelError]);

  function onRowAdd(newData) {
    return new Promise((resolve, reject) => {
      doCreateRakeLevel({
        url: endpoints.rakeLevels,
        params: { ...newData,
          threshold: Number(newData.threshold),
          rake: Number(newData.rake)
        },
        onSuccess: ({ _id }) => {
          setRakeLevelList((rakeLevelList) => [{ ...newData, _id }, ...rakeLevelList]);
          infoService.sendInfo('Уровень рейка был успешно создан!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowUpdate(newData, oldData) {
    return new Promise((resolve, reject) => {
      doUpdateRakeLevel({
        url: `${endpoints.rakeLevels}/${oldData._id}`,
        params: {
          threshold: Number(newData.threshold),
          rake: Number(newData.rake)
        },
        onSuccess: () => {
          setRakeLevelList((rakeLevelList) =>
            rakeLevelList.map((rakeLevel) => (rakeLevel._id === newData._id ? { ...newData } : rakeLevel)),
          );
          infoService.sendInfo('Уровень рейка был успешно изменен!');

          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowDelete(oldData) {
    return new Promise((resolve, reject) => {
      doUpdateRakeLevel({
        url: `${endpoints.rakeLevels}/${oldData._id}`,
        params: { deleted: true },
        onSuccess: () => {
          setRakeLevelList((rakeLevelList) => rakeLevelList.filter((rakeLevel) => rakeLevel._id !== oldData._id));
          warnService.sendWarn('Уровень рейка был успешно удален!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>
                Управление уровнями рейка
              </p>
            </CardHeader>
            <CardBody>
            {isLoading ? (
                <Box textAlign="center">
                  <BeatLoader size={16} color="#00acc1" loading={true} />
                </Box>
              ) : (
                <MaterialTable
                  title="Уровни рейка"
                  localization={{
                    body: {
                      editTooltip: 'Редактировать',
                      deleteTooltip: 'Удалить',
                      editRow: {
                        deleteText: 'Вы уверены что хотите удалить уровень рейка?',
                      },
                    },
                    header: {
                      actions: 'Действия',
                    },
                  }}
                  columns={[
                    {
                      title: 'Ставка',
                      field: 'threshold',
                      defaultSort: 'asc',
                      validate: (rowData) =>
                        validateField(thresholdSchema, {
                          threshold: rowData.threshold
                        }),
                    },
                    {
                      title: 'Рейк %',
                      field: 'rake',
                      sorting: false,
                      validate: (rowData) =>
                        validateField(rakeSchema, {
                          rake: rowData.rake
                        }),
                    },
                  ]}
                  data={rakeLevelList}
                  options={{
                    filtering: false
                  }}
                  editable={{
                    isEditable: () => true,
                    onRowAddCancelled: () => console.log('Row adding cancelled'),
                    onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                    onRowAdd,
                    onRowUpdate,
                    onRowDelete,
                  }}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}