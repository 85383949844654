import React from 'react';
import { useDrop } from 'react-dnd';
import { useStyles } from './styles';

export default function Square({ i, square, onSquareClick, squareStyles, mobileHover, onDrop }) {
  const classes = useStyles();

  const [{ isOver }, drop] = useDrop({
    accept: ['wp', 'wr', 'wn', 'wb', 'wq', 'wk', 'bp', 'br', 'bn', 'bb', 'bq', 'bk'],

    drop: (props) => {
      onDrop({ from: props.from, to: square });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      key={i}
      className={classes.noselect}
      style={{
        transform: `translate(${(i % 8) * 100}%,${Math.trunc(i / 8) * 100}%)`,
        touchAction: 'none',
        width: '12.5%',
        height: '12.5%',
        top: 'auto',
        position: 'absolute',
        backgroundSize: '100%',
        zIndex: 100,
        left: 0,
        ...squareStyles[square],
      }}
      onClick={() => {
        onSquareClick(square);
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
        className={isOver ? (mobileHover ? classes.hoverMobile : classes.hoverDesktop) : undefined}
      />
    </div>
  );
}
