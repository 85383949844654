import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cardTitleWhite: {
    height: 'auto',
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
});
