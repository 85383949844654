import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class StickerPackService {
  static async create(data) {
    const response = await api.post(endpoints.stickerPacks, data);
    return response;
  }
  static async update(id, data) {
    const response = await api.post(`${endpoints.stickerPacks}/${id}`, data);
    return response;
  }
  static async delete(id) {
    const response = await api.delete(`${endpoints.stickerPacks}/${id}`);
    return response;
  }
}
