import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class TournamentService {
  static async getTournaments() {
    const response = await api.get(endpoints.tournaments);
    return response;
  }

  static async createTournament(data) {
    const response = await api.post(endpoints.tournaments, data);
    return response;
  }

  static async updateTournament(id, data) {
    const response = await api.post(`${endpoints.tournaments}/${id}`, { data });
    return response;
  }

  static async deleteTournamentById(id) {
    const response = await api.delete(`${endpoints.tournaments}/${id}`);
    return response;
  }

  static async cancelTournamentById(id) {
    const response = await api.post(`${endpoints.tournaments}/cancel-tournament/${id}`);
    return response;
  }
}
