export const redirectTypes = {
  internal_redirect: 'internal_redirect',
  external_redirect: 'external_redirect',
};

export const bannerTypes = {
  stream: 'stream',
  new_game: 'new_game',
};

export const styleTypes = {
  // TODO: fix naming
  withBackground: 0,
  withAvatar: 1,
};

export const bannerTypesLabels = {
  [bannerTypes.stream]: 'Стрим',
  [bannerTypes.new_game]: 'Новая игра',
};

export const redirectTypesLabels = {
  [redirectTypes.internal_redirect]: 'Внутреннее перенаправление',
  [redirectTypes.external_redirect]: 'Внешнее перенаправление',
};

export const styleTypesLabels = {
  [styleTypes.withBackground]: 'С фоном',
  [styleTypes.withAvatar]: 'С аватаром',
};
