import React from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';

import { getSupportMediaUrl } from 'utils/getSupportMediaUrl';
import RemoveRedEyeIcon from 'components/Icons/RemoveRedEyeIcon';
import { emitter } from 'shared/emitter/emitter';

import { useStyles } from './styles';

const MessageItem = ({ children, files, position, created }) => {
  const classes = useStyles();
  const isCenter = position === 'center';

  const onOpenFullScreenView = (imageUrl) => {
    emitter.emit('fullscreen.image.open', imageUrl);
  };
  return (
    <div className={cn(classes.container, position)}>
      <div className={cn(classes.contentWrapper, position)}>
        <div className={cn(classes.content, position)}>
          <span className={classes.text}>
            {files?.map((file, i) => (
              <div className={classes.imageBox} key={i}>
                <div
                  className={classes.imageShadow}
                  onClick={() => onOpenFullScreenView(getSupportMediaUrl(file.filename))}
                >
                  <RemoveRedEyeIcon className={classes.openFullScreenIcon} />
                </div>
                <img src={getSupportMediaUrl(file.filename)} className={classes.imageItem} />
              </div>
            )) ?? null}
            {children}
          </span>
        </div>
        {!isCenter && (
          <span className={classes.createdDate}>
            {moment.tz(created, moment.tz.guess()).format('HH:mm')}
          </span>
        )}
      </div>
    </div>
  );
};

export default MessageItem;
