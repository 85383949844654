import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import { errorService, infoService } from 'services/alert/services';
import { Nullable } from 'shared/types';
import { emitter } from 'shared/emitter';
import { ICreateRatingHistoryParams, RatingHistoryService } from 'services/api/rating-history';
import { NumberHelper } from 'shared/helpers/NumberHelper';

import { IPayload } from './types';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

const CreateRatingHistoryForm = () => {
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState<Nullable<IPayload>>(null);

  useEffect(() => {
    emitter.on('ratingHistory:createForm:open', (data: IPayload) => {
      setPayload(data);
      setOpen(true);
    });
    emitter.on('ratingHistory:createForm:close', () => {
      setPayload(null);
      setOpen(false);
    });
  }, []);

  const handleClose = () => {
    emitter.emit('ratingHistory:createForm:close');
  };

  const onSubmit = async (values) => {
    try {
      const data: ICreateRatingHistoryParams = {
        user: values.user,
        description: values.description,
        amount: NumberHelper.parseToNumber(values.amount, 0),
      };
      if (values.gameId.trim()) {
        data.gameId = values.gameId;
      }
      await RatingHistoryService.create(data);
      infoService.sendInfo('Рейтинг обновлен');

      handleClose();
      payload?.onSuccess();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
          Обновить рейтинг для пользователя
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ amount: 0, gameId: '', description: '', user: payload?.userId }}
            validationSchema={Yup.object().shape({
              amount: Yup.number().required('Введите рейтинг'),
            })}
            onSubmit={onSubmit}
            render={({ values, errors, handleSubmit, handleChange, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl component="fieldset" fullWidth>
                    <div style={{ color: 'red', fontSize: 12 }}>{errors.amount as string}</div>
                    <br />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.amount}
                      name="amount"
                      autoFocus
                      label="Рейтинг"
                      value={values.amount}
                      placeholder="Введите число"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.description}
                      name="description"
                      label="Описание"
                      value={values.description}
                      placeholder="Введите описание"
                      fullWidth
                    />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.gameId}
                      name="gameId"
                      label="Game id"
                      value={values.gameId}
                      placeholder="ID игры"
                      fullWidth
                    />
                  </FormControl>

                  <DialogActions>
                    <Button color="secondary" variant="contained">
                      Отмена
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      Создать
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateRatingHistoryForm;
